/*
    '': {
        'en': "",
        'es': "",
        'fr': "",
        'it': "", 
        'ca': "",
    },
*/

export const book_prisma_trans = {
    '7042': {
        'en': "This dress in an intense electric blue stands out for its fluid and timeless design, perfect for exuding sophistication at any special event. Crafted in an elegant crepe fabric, its flared cut provides movement and comfort, while the three-quarter sleeves balance elegance with a practical touch. Teresa Ripoll's signature neckline enhances and flatters the figure, subtly framing the face. A design tailored for women who value classic elegance with a modern touch.",
        'es': "Este vestido de un intenso azul eléctrico destaca por su diseño fluido y atemporal, perfecto para transmitir sofisticación en cualquier evento especial. Confeccionado en un elegante tejido de crepé, su corte acampanado aporta movimiento y comodidad, mientras que las mangas tres cuartos equilibran la elegancia con un aire práctico. El cuello característico de Teresa Ripoll realza y estiliza la figura, enmarcando sutilmente el rostro. Un diseño pensado para mujeres que valoran la elegancia clásica con un toque moderno.",
        'fr': "",
        'it': "Questo abito in un intenso blu elettrico si distingue per il suo design fluido e senza tempo, perfetto per trasmettere sofisticatezza in qualsiasi evento speciale. Realizzato in un elegante tessuto crepé, il taglio svasato dona movimento e comfort, mentre le maniche a tre quarti bilanciano eleganza e praticità. Lo scollo caratteristico di Teresa Ripoll valorizza e slancia la figura, incorniciando delicatamente il viso. Un design pensato per donne che apprezzano l'eleganza classica con un tocco moderno.", 
        'ca': "Aquest vestit d’un intens blau elèctric destaca pel seu disseny fluid i atemporal, perfecte per transmetre sofisticació en qualsevol esdeveniment especial. Confeccionat en un elegant teixit de crepè, el seu tall evasé aporta moviment i comoditat, mentre que les mànigues tres quarts equilibren l’elegància amb un toc pràctic. El coll característic de Teresa Ripoll realça i estilitza la figura, emmarcant subtilment el rostre. Un disseny pensat per a dones que valoren l’elegància clàssica amb un toc modern.",
    },
    '7043': {
        'en': "This dress in a vibrant electric blue is a statement of style and sophistication, perfect for unique events. Crafted from luxurious jacquard mikado, it stands out for its rich texture and subtle sheen, adding an air of exclusivity. The flared skirt not only brings fluidity to the design but also ensures ease of movement, while the three-quarter sleeves offer a balanced and modern aesthetic. Teresa Ripoll's distinctive neckline gracefully frames the face, adding a contemporary touch. The peplum detail at the waist creates a visual effect that defines and flatters the silhouette, blending femininity with character. An ideal choice for women who seek to stand out with elegance and personality.",
        'es': "Este vestido en un vibrante azul eléctrico es una declaración de estilo y sofisticación, ideal para eventos únicos. Confeccionado en un lujoso jacquard mikado, destaca por su textura rica y su delicado brillo, que aportan un aire de exclusividad. La falda con corte acampanado no solo añade fluidez al diseño, sino que también garantiza comodidad en el movimiento, mientras que las mangas tres cuartos ofrecen una estética equilibrada y moderna. El elegante cuello distintivo de Teresa Ripoll enmarca el rostro con gracia, aportando un toque contemporáneo. El detalle de peplum en la cintura crea un efecto visual que define y estiliza la silueta, proporcionando un diseño que combina feminidad y carácter. Una elección ideal para mujeres que buscan destacar con elegancia y personalidad.",
        'fr': "",
        'it': "Questo abito in un vibrante blu elettrico è un’affermazione di stile e raffinatezza, perfetto per eventi unici. Realizzato in un lussuoso jacquard mikado, si distingue per la sua ricca texture e la sua delicata lucentezza, che conferiscono un’aura di esclusività. La gonna svasata non solo dona fluidità al design, ma garantisce anche libertà di movimento, mentre le maniche a tre quarti offrono un’estetica bilanciata e moderna. Il caratteristico scollo di Teresa Ripoll incornicia il viso con eleganza, aggiungendo un tocco contemporaneo. Il dettaglio del peplum in vita crea un effetto visivo che definisce e valorizza la silhouette, combinando femminilità e carattere. Una scelta ideale per donne che desiderano distinguersi con classe e personalità.", 
        'ca': "Aquest vestit d’un vibrant blau elèctric és una autèntica declaració de sofisticació i estil, ideal per a esdeveniments únics. Confeccionat en un ric jacquard mikado, destaca per la seva textura refinada i una brillantor subtil que li atorguen un aire exclusiu. La faldilla amb un tall acampanat aporta no només fluïdesa al disseny, sinó també llibertat de moviment, mentre que les mànigues tres quarts ofereixen una estètica moderna i equilibrada. El coll distintiu de Teresa Ripoll emmarca el rostre amb elegància i un toc contemporani. El detall del peplum a la cintura crea un efecte visual que defineix i estilitza la silueta, combinant feminitat i caràcter. Una elecció perfecta per a dones que volen destacar amb elegància i personalitat.",
    },
    '7044': {
        'en': "This dress in a sophisticated St. Gillian blue combines elegance and originality, making it perfect for special occasions. Crafted from refined crepe fabric, its design features a flared skirt that adds fluidity and movement, subtly enhancing the figure. The bateau neckline elegantly highlights the neck and shoulders, adding a touch of distinction and femininity. The voluminous sleeves, adorned with striking contrasting ruffles, become the standout feature, bringing character and modernity to the ensemble. A dress designed for women who seek to stand out with a unique, sophisticated, and personality-filled style.",
        'es': "Este vestido en un sofisticado color azul St. Gillian combina elegancia y originalidad, siendo perfecto para ocasiones especiales. Confeccionado en un refinado tejido de crepé, su diseño incluye una falda evasé que aporta fluidez y movimiento, estilizando la figura con delicadeza. El escote barco realza de forma elegante el cuello y los hombros, añadiendo un toque de distinción y feminidad. Las mangas voluminosas, decoradas con llamativos volantes en contraste, se convierten en el elemento protagonista, aportando carácter y modernidad al conjunto. Un vestido diseñado para mujeres que buscan destacar con un estilo único, sofisticado y lleno de personalidad.",
        'fr': "",
        'it': "Questo abito in un sofisticato blu St. Gillian combina eleganza e originalità, rendendolo perfetto per occasioni speciali. Realizzato in un raffinato tessuto di crepé, il design presenta una gonna svasata che dona fluidità e movimento, valorizzando delicatamente la figura. Lo scollo a barca mette in risalto il collo e le spalle in modo elegante, aggiungendo un tocco di distinzione e femminilità. Le maniche voluminose, arricchite da volant a contrasto, diventano il punto focale, conferendo carattere e modernità all'insieme. Un abito pensato per donne che desiderano distinguersi con uno stile unico, sofisticato e pieno di personalità.", 
        'ca': "Aquest vestit d’un sofisticat blau St. Gillian combina elegància i originalitat, sent perfecte per a ocasions especials. Confeccionat en un refinat teixit de crepè, el seu disseny inclou una faldilla evasé que aporta fluïdesa i moviment, estilitzant la figura amb delicadesa. L’escot barco ressalta elegantment el coll i les espatlles, afegint un toc de distinció i feminitat. Les mànigues voluminoses, decorades amb cridaners volants en contrast, es converteixen en l’element protagonista, aportant caràcter i modernitat al conjunt. Un vestit pensat per a dones que volen destacar amb un estil únic, sofisticat i ple de personalitat.",
    },
    '7045': {
        'en': "This dress in an elegant ice blue color exemplifies classic sophistication with modern details. Crafted from luxurious jacquard mikado fabric, its subtly lustrous texture adds a touch of exclusivity. The fitted cut flatters the silhouette, making it an ideal choice for important events. The design is distinguished by Teresa Ripoll's iconic neckline, a bateau style with delicate pleats at the center front that create the effect of a knot, adding a distinctive and elegant touch. The three-quarter sleeves complete the look, offering a perfect balance of sophistication and comfort. A dress designed for women who appreciate classic elegance with a unique and refined flair.",
        'es': "Este vestido en un elegante color azul hielo es un ejemplo de sofisticación clásica con detalles modernos. Confeccionado en un lujoso tejido de jacquard mikado, su textura sutilmente brillante añade un toque de exclusividad. El corte entallado estiliza la silueta, convirtiéndolo en una opción ideal para eventos importantes. El diseño se caracteriza por el icónico cuello de Teresa Ripoll, un escote barco con delicados pliegues en el centro frontal que evocan la apariencia de un nudo, añadiendo un toque distintivo y elegante. Las mangas tres cuartos completan el look, ofreciendo un equilibrio perfecto entre sofisticación y comodidad. Un vestido pensado para mujeres que valoran la elegancia clásica con un toque único y refinado.",
        'fr': "",
        'it': "Questo abito in un elegante colore blu ghiaccio è un esempio di sofisticatezza classica con dettagli moderni. Realizzato in un lussuoso tessuto jacquard mikado, la sua texture delicatamente lucente aggiunge un tocco di esclusività. Il taglio aderente valorizza la silhouette, rendendolo una scelta ideale per eventi importanti. Il design si distingue per il classico scollo iconico di Teresa Ripoll, uno stile a barca con delicati drappeggi al centro frontale che evocano l'effetto di un nodo, aggiungendo un tocco distintivo ed elegante. Le maniche a tre quarti completano il look, offrendo un equilibrio perfetto tra raffinatezza e comfort. Un abito pensato per donne che apprezzano l'eleganza classica con un tocco unico e ricercato.", 
        'ca': "Aquest vestit d’un elegant color blau gel és un exemple de sofisticació clàssica amb detalls moderns. Confeccionat en un luxós teixit de jacquard mikado, la seva textura subtilment brillant aporta un toc d’exclusivitat. El tall entallat estilitza la silueta, fent-lo una elecció ideal per a esdeveniments importants. El disseny es distingeix pel clàssic coll icònic de Teresa Ripoll, un escot barco amb delicats plecs al centre frontal que evoquen l’aparença d’un nus, afegint un toc distintiu i elegant. Les mànigues tres quarts completen el conjunt, oferint un equilibri perfecte entre sofisticació i comoditat. Un vestit pensat per a dones que valoren l’elegància clàssica amb un toc únic i refinat.",
    },
    '7046': {
        'en': "This dress in a delicate sky blue exudes elegance and sophistication. Its fitted design subtly enhances the figure, projecting a refined image. The three-quarter sleeves strike a perfect balance between formality and comfort, making it ideal for important events such as weddings or ceremonies. Teresa Ripoll's signature neckline enhances and flatters the figure, while the small decorative bow adds a feminine touch. The clean and minimalist finish allows for easy pairing with either discreet or bold accessories, perfectly adapting to the style of any woman who wishes to stand out with elegance and confidence.",
        'es': "Este vestido en un delicado azul cielo es la esencia de la elegancia y la sofisticación. Su diseño de corte entallado realza la figura de manera sutil, proyectando una imagen refinada. Las mangas tres cuartos aportan un equilibrio perfecto entre formalidad y comodidad, ideal para eventos importantes como bodas o ceremonias. El cuello característico de Teresa Ripoll realza y estiliza la figura, mientras que el pequeño lazo decorativo refuerza su aire femenino. El acabado limpio y minimalista permite combinarlo fácilmente con accesorios discretos o más llamativos, adaptándose al estilo de cada mujer que desee destacar con elegancia y confianza.",
        'fr': "",
        'it': "Questo abito in un delicato azzurro cielo emana eleganza e raffinatezza. Il suo taglio aderente valorizza la figura con discrezione, proiettando un’immagine sofisticata. Le maniche a tre quarti offrono un perfetto equilibrio tra formalità e comfort, rendendolo ideale per eventi importanti come matrimoni o cerimonie. Lo scollo caratteristico di Teresa Ripoll valorizza e slancia la figura, mentre il piccolo fiocco decorativo aggiunge un tocco femminile. La finitura pulita e minimalista consente di abbinarlo facilmente ad accessori discreti o audaci, adattandosi perfettamente allo stile di ogni donna che desideri distinguersi con eleganza e sicurezza.", 
        'ca': "Aquest vestit, en un delicat blau cel, desprèn elegància i sofisticació. El seu disseny ajustat realça subtilment la figura, projectant una imatge refinada. Les mànigues de tres quarts aconsegueixen un equilibri perfecte entre formalitat i comoditat, fent-lo ideal per a esdeveniments importants com casaments o cerimònies. L'escot característic de Teresa Ripoll afavoreix i estilitza la figura, mentre que el petit llaç decoratiu hi aporta un toc femení. L'acabat net i minimalista permet combinar-lo fàcilment amb accessoris discrets o atrevits, adaptant-se perfectament a l'estil de qualsevol dona que desitgi destacar amb elegància i seguretat.",
    },
    '7047': {
        'en': "Dress crafted in petrol-colored crepe, featuring an elegant and flowing design that combines comfort and style. The asymmetrical skirt adds dynamism, while the three-quarter sleeves balance sobriety and sophistication. The smooth fall of the fabric and the impeccable cut enhance the figure with elegance. Perfect for pairing with accessories that add a personal and distinguished touch.",
        'es': "Vestido confeccionado en crepé de color petróleo, con un diseño elegante y fluido que combina comodidad y estilo. La falda asimétrica aporta dinamismo, mientras que las mangas tres cuartos equilibran sobriedad y sofisticación. La caída suave del tejido y el corte impecable realzan la figura con elegancia. Ideal para combinar con accesorios que aporten un toque personal y distinguido.",
        'fr': "",
        'it': "Abito realizzato in crêpe color petrolio, con un design elegante e fluido che combina comfort e stile. La gonna asimmetrica dona dinamicità, mentre le maniche a tre quarti equilibrano sobrietà e raffinatezza. La caduta morbida del tessuto e il taglio impeccabile valorizzano la figura con eleganza. Perfetto da abbinare ad accessori che aggiungono un tocco personale e distinto.", 
        'ca': "Vestit confeccionat en crep de color petroli, amb un disseny elegant i fluït que combina comoditat i estil. La faldilla asimètrica aporta dinamisme, mentre que les mànigues de tres quarts equilibren sobrietat i sofisticació. La caiguda suau del teixit i el tall impecable realcen la figura amb elegància. Ideal per combinar amb accessoris que aportin un toc personal i distingit.",
    },
    '7048': {
        'en': "Dress crafted in jacquard mikado in an elegant emerald green color. Its impeccable cut and flared skirt add volume and movement, while the structured neckline, a signature of Teresa Ripoll, elongates and enhances the silhouette with a sophisticated and timeless touch. The three-quarter sleeves complete the design, offering the perfect balance between elegance and comfort. A dress that combines tradition and modernity, ideal for standing out with style and distinction at any special event.",
        'es': "Vestido confeccionado en micado jacquard de un elegante color verde esmeralda. Su corte impecable y la falda con vuelo aportan volumen y movimiento, mientras que el escote estructurado, característico de la firma Teresa Ripoll, alarga y estiliza la silueta con un toque sofisticado y atemporal. Las mangas de tres cuartos completan el diseño, ofreciendo el equilibrio perfecto entre elegancia y comodidad. Un vestido que combina tradición y modernidad, ideal para lucir con estilo y distinción en cualquier evento especial.",
        'fr': "",
        'it': "Abito realizzato in micado jacquard di un elegante colore verde smeraldo. Il taglio impeccabile e la gonna svasata donano volume e movimento, mentre lo scollo strutturato, firma distintiva di Teresa Ripoll, allunga e valorizza la silhouette con un tocco sofisticato e senza tempo. Le maniche a tre quarti completano il design, offrendo un perfetto equilibrio tra eleganza e comfort. Un abito che unisce tradizione e modernità, ideale per distinguersi con stile e classe in qualsiasi evento speciale.", 
        'ca': "Vestit confeccionat en micado jacquard d’un elegant color verd maragda. El seu tall impecable i la faldilla amb vol aporten volum i moviment, mentre que l’escot estructurat, un clàssic de la firma Teresa Ripoll, allarga i estilitza la silueta amb un toc sofisticat i atemporal. Les mànigues de tres quarts completen el disseny, oferint l’equilibri perfecte entre elegància i comoditat. Un vestit que combina tradició i modernitat, ideal per destacar amb estil i distinció en qualsevol esdeveniment especial.",
    },
    '7049': {
        'en': "Short dress crafted in red crepe, a design that combines elegance and modernity. The fitted cut subtly enhances the figure, while the structured sleeve details add dynamism and sophistication to the look. The three-quarter sleeves offer a refined and balanced touch, ideal for special occasions. A versatile and timeless dress, perfect to pair with accessories that highlight its intensity and elegance.",
        'es': "Vestido corto confeccionado en crepé rojo, un diseño que combina elegancia y modernidad. El corte ajustado realza la figura con sutileza, mientras que los detalles estructurados en las mangas aportan dinamismo y sofisticación al conjunto. Las mangas tres cuartos ofrecen un toque refinado y equilibrado, ideal para ocasiones especiales. Un vestido versátil y atemporal, perfecto para combinar con accesorios que resalten su intensidad y elegancia.",
        'fr': "",
        'it': "Abito corto realizzato in crêpe rosso, un design che unisce eleganza e modernità. Il taglio aderente valorizza la figura con delicatezza, mentre i dettagli strutturati sulle maniche aggiungono dinamicità e raffinatezza all’insieme. Le maniche a tre quarti offrono un tocco raffinato ed equilibrato, ideale per occasioni speciali. Un abito versatile e senza tempo, perfetto da abbinare ad accessori che ne esaltano l’intensità ed eleganza.", 
        'ca': "Vestit curt confeccionat en crep vermell, un disseny que fusiona elegància i modernitat. El tall ajustat realça la figura amb subtilesa, mentre que els detalls estructurats a les mànigues aporten dinamisme i sofisticació al conjunt. Les mànigues de tres quarts ofereixen un toc refinat i equilibrat, ideal per a esdeveniments especials. Un vestit versàtil i atemporal, perfecte per combinar amb accessoris que destaquin la seva intensitat i elegància.",
    },
    '7050': {
        'en': "Set crafted in bougainvillea-colored mikado jacquard. The bolero stands out with its Japanese sleeves and cuffs featuring a special cut that can be turned up, adding structure and a refined touch. The high collar completes the design with elegance and modernity. The sleeveless dress features a flattering V-neckline with a delicate center slit and is fitted under the bust, naturally enhancing the figure. Its impeccable cut offers an excellent fit for all sizes, combining comfort and sophistication in every detail.",
        'es': "Conjunto confeccionado en mikado jacquard buganvilla. La torera destaca por sus mangas japonesas y puños con un corte especial que permite darles la vuelta, aportando estructura y un toque refinado. El cuello alto completa el diseño con elegancia y modernidad. El vestido, sin mangas, presenta un favorecedor escote en V con un delicado corte central y está entallado bajo el pecho, realzando la figura de manera natural. Su corte impecable ofrece una excelente forma para todas las tallas, combinando comodidad y sofisticación en cada detalle.",
        'fr': "",
        'it': "Completo realizzato in micado jacquard color bouganville. La torera si distingue per le maniche giapponesi e i polsini con un taglio speciale che permette di risvoltarli, aggiungendo struttura e un tocco raffinato. Il collo alto completa il design con eleganza e modernità. L'abito, senza maniche, presenta un scollo a V con un delicato taglio centrale ed è aderente sotto il seno, valorizzando naturalmente la figura. Il taglio impeccabile offre una forma perfetta per tutte le taglie, combinando comodità e raffinatezza in ogni dettaglio.", 
        'ca': "Conjunt confeccionat en micado jacquard color buganvilla. La torera destaca per les mànigues japoneses i els punys amb un tall especial que permet donar-los la volta, aportant estructura i un toc refinat. El coll alt completa el disseny amb elegància i modernitat. El vestit, sense mànigues, presenta un afavoridor escot en V amb un delicat tall central i està entallat sota el pit, realçant la figura de manera natural. El seu tall impecable ofereix una excel·lent forma per a totes les talles, combinant comoditat i sofisticació en cada detall.",
    },
    '7051': {
        'en': "Dress crafted in bougainvillea-colored mikado jacquard, an elegant and sophisticated design. The structured necklinewith a bow detail adds volume and enhances the figure with a modern and distinctive touch. The A-line skirt provides movement and comfort, perfectly balancing the silhouette, while the three-quarter sleeves add a refined and timeless feel. A piece that combines style and elegance, perfect for standing out on any special occasion.",
        'es': "Vestido confeccionado en mikado jacquard color buganvilla, un diseño elegante y sofisticado. El escote estructuradocon detalle de lazo aporta volumen y realza la figura con un toque moderno y distintivo. La falda evasé proporciona movimiento y comodidad, equilibrando perfectamente la silueta, mientras que las mangas tres cuartos añaden un aire refinado y atemporal. Una pieza que combina estilo y elegancia, ideal para destacar en cualquier ocasión especial.",
        'fr': "",
        'it': "Abito realizzato in micado jacquard color bouganville, un design elegante e sofisticato. Lo scollo strutturato con dettaglio a fiocco dona volume e valorizza la figura con un tocco moderno e distintivo. La gonna svasata offre movimento e comfort, bilanciando perfettamente la silhouette, mentre le maniche a tre quartiaggiungono un'aria raffinata e senza tempo. Un capo che unisce stile ed eleganza, ideale per distinguersi in qualsiasi occasione speciale.", 
        'ca': "Vestit confeccionat en micado jacquard color buganvilla, un disseny elegant i sofisticat. L’escot estructurat amb detall de llaç aporta volum i realça la figura amb un toc modern i distintiu. La faldilla evasé proporciona moviment i comoditat, equilibrant perfectament la silueta, mentre que les mànigues de tres quarts afegeixen un aire refinat i atemporal. Una peça que combina estil i elegància, ideal per destacar en qualsevol ocasió especial.",
    },
    '7052': {
        'en': "Two-piece set crafted in bougainvillea-colored crepe. The blouse features a flowing design with an elegant bow detail at the neckline and an asymmetrical cut at the back, adding movement and originality. The three-quarter sleevescomplete the look with a touch of sophistication and functionality. The wide-leg trousers, with their impeccable drape, enhance the figure while providing comfort without sacrificing elegance. A versatile and contemporary ensemble, perfect for special events or celebrations where style and distinction are key.",
        'es': "Conjunto de dos piezas confeccionado en crepé color buganvilla. La blusa destaca por su diseño fluido con un elegante lazo en el escote y un corte asimétrico en la parte trasera que aporta movimiento y originalidad. Las mangas tres cuartoscompletan el look con un toque sofisticado y funcional. El pantalón de pierna ancha, de caída impecable, estiliza la figura y ofrece comodidad sin renunciar a la elegancia. Un conjunto versátil y actual, perfecto para eventos especiales o celebraciones donde destacar con estilo y distinción.",
        'fr': "",
        'it': "Completo due pezzi realizzato in crêpe color bouganville. La blusa si distingue per il suo design fluido con un elegante fiocco allo scollo e un taglio asimmetrico nella parte posteriore, che dona movimento e originalità. Le maniche a tre quarti completano il look con un tocco di raffinatezza e funzionalità. I pantaloni a gamba larga, con una caduta impeccabile, valorizzano la figura offrendo comfort senza rinunciare all'eleganza. Un completo versatile e contemporaneo, perfetto per eventi speciali o celebrazioni dove spiccare con stile e distinzione.", 
        'ca': "Conjunt de dues peces confeccionat en crep color buganvilla. La brusa destaca pel seu disseny fluid amb un elegant lllaç a l'escot i un tall asimètric a la part del darrere, que aporta moviment i originalitat. Les mànigues de tres quartscompleten el look amb un toc de sofisticació i funcionalitat. Els pantalons de cama ampla, amb una caiguda impecable, estilitzen la figura i ofereixen comoditat sense renunciar a l’elegància. Un conjunt versàtil i actual, perfecte per a esdeveniments especials o celebracions on destacar amb estil i distinció.",
    },
    '7053': {
        'en': "Dress crafted in pink mikado jacquard, a design that combines elegance and distinction. Its structured shirt collar and three-quarter sleeves bring a sophisticated and refined touch. The shaped cuff allows for a turned-up finish, adding a distinctive and versatile detail. The A-line skirt provides movement and fluidity, balancing the silhouette with a timeless touch. A perfect dress for special events where you want to stand out with class and subtlety.",
        'es': "Vestido confeccionado en mikado jacquard rosa, un diseño que combina elegancia y distinción. Su cuello camisero estructurado y las mangas tres cuartos aportan un aire sofisticado y refinado. El puño con forma permite darle la vuelta, creando un detalle distintivo y versátil. El corte evasé de la falda añade movimiento y fluidez, equilibrando la silueta con un toque atemporal. Un vestido perfecto para eventos especiales donde destacar con clase y sutileza.",
        'fr': "",
        'it': "Abito realizzato in micado jacquard rosa, un design che unisce eleganza e distinzione. Il suo colletto a camicia strutturato e le maniche a tre quarti aggiungono un tocco sofisticato e raffinato. Il polsino sagomato permette di risvoltarlo, creando un dettaglio distintivo e versatile. Il taglio svasato della gonna dona movimento e fluidità, equilibrando la silhouette con un tocco senza tempo. Un abito perfetto per eventi speciali dove distinguersi con classe e sobrietà.", 
        'ca': "Vestit confeccionat en micado jacquard rosa, un disseny que combina elegància i distinció. El seu coll camiser estructurat i les mànigues de tres quarts aporten un aire sofisticat i refinat. El puny amb forma permet donar-li la volta, creant un detall distintiu i versàtil. El tall evasé de la faldilla afegeix moviment i fluïdesa, equilibrant la silueta amb un toc atemporal. Un vestit perfecte per a esdeveniments especials on destacar amb classe i subtilesa.",
    },
    '7054': {
        'en': "Dress crafted in pink mikado jacquard, notable for its elegance and delicacy. The short cape-like overlay adds volume and balance to the design, creating a sophisticated and modern effect. The fitted cut enhances the figure, while the front slit in the skirt adds movement and a subtly bold touch. An impeccable design that combines style and distinction, perfect for special events.",
        'es': "Vestido confeccionado en mikado jacquard rosa, que destaca por su elegancia y delicadeza. La pieza superior tipo capa corta aporta volumen y equilibrio al diseño, creando un efecto sofisticado y moderno. El corte ajustado realza la figura, mientras que la abertura frontal en la falda añade movimiento y un toque sutilmente atrevido. Un diseño impecable que combina estilo y distinción, ideal para eventos especiales.",
        'fr': "",
        'it': "Abito realizzato in micado jacquard rosa, caratterizzato da eleganza e delicatezza. Il soprabito corto a mantella dona volume ed equilibrio al design, creando un effetto sofisticato e moderno. Il taglio aderente valorizza la figura, mentre lo spacco frontale nella gonna aggiunge movimento e un tocco sottilmente audace. Un design impeccabile che unisce stile e distinzione, perfetto per occasioni speciali.", 
        'ca': "Vestit confeccionat en micado jacquard rosa, que destaca per la seva elegància i delicadesa. La peça superior tipus capa curta aporta volum i equilibri al disseny, creant un efecte sofisticat i modern. El tall cenyit realça la figura, mentre que l’obertura frontal de la faldilla afegeix moviment i un toc subtilment atrevit. Un disseny impecable que combina estil i distinció, ideal per a esdeveniments especials.",
    },
    '7055': {
        'en': "Dress crafted in gold mikado jacquard, combining elegance and sophistication in a unique design. The wrap necklinewith a bow detail and structured pleats adds volume and a distinctive touch, subtly enhancing the figure. Its fitted cut flatters the silhouette, while the three-quarter sleeves balance the look with class and modernity. An impeccable and timeless piece, perfect for dazzling at special occasions.",
        'es': "Vestido confeccionado en mikado jacquard dorado, que combina elegancia y sofisticación en un diseño único. El escote envolvente con detalle de lazo y pliegues estructurados aporta volumen y un toque distintivo, realzando la figura con sutileza. Su corte entallado estiliza la silueta, mientras que las mangas tres cuartos equilibran el look con clase y modernidad. Una pieza impecable y atemporal, ideal para deslumbrar en ocasiones especiales.",
        'fr': "",
        'it': "Abito realizzato in micado jacquard dorato, che unisce eleganza e raffinatezza in un design unico. Lo scollo avvolgentecon dettaglio a fiocco e pieghe strutturate dona volume e un tocco distintivo, valorizzando la figura con delicatezza. Il suo taglio aderente slancia la silhouette, mentre le maniche a tre quarti bilanciano il look con classe e modernità. Un capo impeccabile e senza tempo, perfetto per brillare in occasioni speciali.", 
        'ca': "Vestit confeccionat en micado jacquard daurat, que combina elegància i sofisticació en un disseny únic. L’escot envoltant amb detall de llaç i plecs estructurats aporta volum i un toc distintiu, realçant la figura amb subtilesa. El seu tall cenyit estilitza la silueta, mentre que les mànigues de tres quarts equilibren el conjunt amb classe i modernitat. Una peça impecable i atemporal, perfecta per brillar en ocasions especials.",
    },
}