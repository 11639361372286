
export const filmsData = [
    { video: "img/films/videos/blue.mp4",                   poster: "img/films/blue.jpg",         name: "BLUE"},
    { video: "img/films/videos/making-dreams.mp4",          poster: "img/films/makingdreams.jpg", name: "MAKING DREAMS"},
    { video: "img/films/videos/yelow.mp4",                  poster: "img/films/yelow.jpg",        name: "YELOW"},
    { video: "img/films/videos/party.mp4",                  poster: "img/films/party.jpg",        name: "PARTY"},
    { video: "img/films/videos/red.mp4",                    poster: "img/films/rojo.jpg",         name: "RED"},
    { video: "img/films/videos/pink.mp4",                   poster: "img/films/rosa.jpg",         name: "PINK"},
    { video: "img/films/videos/city-1080.mp4",              poster: "img/films/city.jpg",         name: "CITY TWO COLLECTION"},
    { video: "img/films/videos/charming-collection.mp4",    poster: "img/films/charmings.jpg",    name: "CHARMING COLLECTION"},
    { video: "img/films/videos/45years.mp4",                poster: "img/films/atelier.jpg",      name: "45 YEARS OF COLLECTIONS" },
    { video: "img/films/videos/color-collection.mp4",       poster: "img/films/color.jpg",        name: "COLOR COLLECTION" },
]

/* JA NO S'UTILITZA ELS VÍDEOS DE VIMEO
export const filmsDataVimeo = [
    { video: "301393531",   poster: "img/films/blue.jpg",         name: "BLUE"},
    { video: "301361148",   poster: "img/films/makingdreams.jpg", name: "MAKING DREAMS"},
    { video: "301393628",   poster: "img/films/yelow.jpg",        name: "YELOW"},
    { video: "301567324",   poster: "img/films/party.jpg",        name: "PARTY"},
    { video: "301393719",   poster: "img/films/rojo.jpg",         name: "RED"},
    { video: "301393761",   poster: "img/films/rosa.jpg",         name: "PINK"},
    { video: "241209641",   poster: "img/films/city.jpg",         name: "CITY TWO COLLECTION"},
    { video: "190548755",   poster: "img/films/charmings.jpg",    name: "CHARMING COLLECTION"},
    { video: "142366577",   poster: "img/films/atelier.jpg",      name: "45 YEARS OF COLLECTIONS" },
    { video: "78810994",    poster: "img/films/color.jpg",        name: "COLOR COLLECTION" },
]
*/
