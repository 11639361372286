/*
    '': {
        'en': "",
        'es': "",
        'fr': "",
        'it': "", 
        'ca': "",
    },
*/

export const book_heritage_trans = {
    '2230': {
        'en': "Two-piece set featuring a blouse and trousers crafted in pink crepe, a delicate and charming design. The high-neck blouse incorporates an elegant chimney collar that adds sophistication to the ensemble, while the decorative bows on the sides bring a feminine and original touch. The straight trousers, with matching bows on the sides, elongate and flatter the figure, combining comfort and elegance. A modern and refined set, perfect for standing out at any special event with subtlety and style.",
        'es': "Conjunto de blusa y pantalón confeccionado en crepé rosa, un diseño delicado y lleno de encanto. La blusa de corte cerrado incorpora un elegante cuello chimenea que aporta sofisticación al conjunto, mientras que los lazos decorativossituados en los laterales añaden un detalle femenino y original. El pantalón recto, con lazos a juego en los laterales, alarga y estiliza la figura, combinando comodidad y elegancia. Un conjunto moderno y refinado, perfecto para destacar en cualquier evento especial con sutileza y estilo.",
        'fr': "",
        'it': "Completo blusa e pantaloni realizzato in crêpe rosa, un design delicato e pieno di fascino. La blusa a collo chiusopresenta un elegante collo a camino, che aggiunge raffinatezza al capo, mentre i fiocchi decorativi sui lati donano un tocco femminile e originale. I pantaloni dritti, con fiocchi abbinati sui lati, allungano e valorizzano la figura, unendo comfort ed eleganza. Un completo moderno e sofisticato, perfetto per distinguersi in qualsiasi evento speciale con delicatezza e stile.", 
        'ca': "Conjunt de brusa i pantalons confeccionat en crep rosa, un disseny delicat i ple d’encant. La brusa de tall tancatincorpora un elegant coll xemeneia, que aporta sofisticació al conjunt, mentre que els llacets decoratius als laterals afegeixen un toc femení i original. Els pantalons rectes, amb llacets a joc als laterals, allarguen i realcen la figura, combinant comoditat i elegància. Un conjunt modern i refinat, perfecte per destacar en qualsevol esdeveniment especial amb subtilesa i estil.",
    },
    '2231': {
        'en': "Two-piece set featuring a blouse and straight trousers crafted in cerulean blue crepe, an elegant and sophisticated tone. The blouse stands out with hand-painted flowers on the shoulders, a unique and delicate artisanal detail that adds originality to the design. The clean cut and three-quarter sleeves provide comfort and style, while the straight trousers elongate the figure with their impeccable drape. A modern and refined ensemble, perfect for special events where distinction and good taste are key.",
        'es': "Conjunto de blusa y pantalón recto confeccionado en crepé azul cerúlea, un tono elegante y sofisticado. La blusadestaca por las flores pintadas a mano situadas en los hombros, un detalle artesanal único y delicado que aporta originalidad al diseño. El corte limpio y las mangas tres cuartos brindan comodidad y estilo, mientras que el pantalón recto estiliza la figura con su caída impecable. Un conjunto moderno y refinado, ideal para eventos especiales donde la distinción y el buen gusto son protagonistas.",
        'fr': "",
        'it': "Completo composto da blusa e pantaloni dritti realizzato in crêpe blu ceruleo, una tonalità elegante e sofisticata. La blusa si distingue per i fiori dipinti a mano sulle spalle, un dettaglio artigianale unico e delicato che aggiunge originalità al design. Il taglio pulito e le maniche a tre quarti offrono comfort e stile, mentre i pantaloni dritti slanciano la figura con la loro caduta impeccabile. Un completo moderno e raffinato, ideale per eventi speciali dove la distinzione e il buon gusto sono protagonisti.", 
        'ca': "Conjunt de brusa i pantalons rectes confeccionat en crep blau cerúlea, un to elegant i sofisticat. La brusa destaca per les flors pintades a mà situades a les espatlles, un detall artesanal únic i delicat que aporta originalitat al disseny. El tall net i les mànigues de tres quarts ofereixen comoditat i estil, mentre que els pantalons rectes estilitzen la figura amb la seva caiguda impecable. Un conjunt modern i refinat, ideal per a esdeveniments especials on la distinció i el bon gust són protagonistes.",
    },
    '3812': {
        'en': "Elegant set in cerulean blue crepe, combining matte and shiny finishes, creating a sophisticated and unique visual effect. The ensemble consists of a fitted long dress and a structured bolero jacket. The decorative bias detailing on both pieces defines and enhances the silhouette with subtlety, perfectly highlighting the figure. The three-quarter sleeve bolero, with its structured and piped design, balances and complements the look, creating a refined and harmonious effect. A perfect choice for special occasions where elegance and style take center stage.",
        'es': "Elegante conjunto en crepé azul cerúlea, que combina acabados en mate y brillo, aportando un efecto visual sofisticado y único. El conjunto está compuesto por un vestido largo entallado y una torera estructurada. Los bieses decorativospresentes en ambas piezas definen la silueta y la estilizan con sutileza, realzando la figura de manera impecable. La torera de manga tres cuartos, con su diseño estructurado y ribeteado, equilibra y complementa el look, creando un efecto refinado y armonioso. Una opción perfecta para ocasiones especiales donde la elegancia y el estilo son protagonistas.",
        'fr': "",
        'it': "Elegante completo in crêpe blu ceruleo, che combina finiture opache e lucide, creando un effetto visivo sofisticato e unico. L'insieme è composto da un abito lungo aderente e una torera strutturata. I bordi decorativi presenti su entrambi i capi definiscono e valorizzano la silhouette con delicatezza, esaltando perfettamente la figura. La torera a maniche tre quarti, con il suo design strutturato e rifinito a sbieco, bilancia e completa il look, creando un effetto raffinato e armonioso. Una scelta perfetta per occasioni speciali dove l'eleganza e lo stile sono protagonisti.", 
        'ca': "Elegant conjunt en crep blau cerúlea, que combina acabats mate i brillant, creant un efecte visual sofisticat i únic. El conjunt està format per un vestit llarg entallat i una torera estructurada. Els biaixos decoratius presents en totes dues peces defineixen i realcen la silueta amb subtilesa, ressaltant la figura de manera impecable. La torera de màniga tres quarts, amb el seu disseny estructurat i rivetejat, equilibra i complementa el conjunt, creant un efecte refinat i harmoniós. Una elecció perfecta per a ocasions especials on l’elegància i l’estil són protagonistes.",
    },
    '3813': {
        'en': "Dress crafted in cerulean blue crepe, featuring a clean and sophisticated design. The front showcases the signature Teresa Ripoll neckline, which elegantly enhances the shoulders and neckline with unmistakable style. The back features a V-shaped neckline, covered to the bra line, providing comfort and discretion. A delicate hand-painted floral detail on the back adds an artisanal and exclusive touch, elevating the design with unique refinement. A fitted, short-sleeved dress, perfect for standing out with style on any special occasion.",
        'es': "Vestido confeccionado en crepé azul cerúlea, de diseño limpio y sofisticado. El delantero presenta el cuello característico de Teresa Ripoll, que realza los hombros y el escote con una elegancia inconfundible. La espalda luce un escote en V, tapado hasta la línea del sujetador, aportando comodidad y discreción. Un delicado detalle de flor pintada a mano en la espalda añade un toque artesanal y exclusivo, elevando el diseño con un aire único y refinado. Un vestido entallado y de manga corta, ideal para destacar con estilo en cualquier ocasión especial.",
        'fr': "",
        'it': "Abito realizzato in crêpe blu ceruleo, con un design pulito e sofisticato. La parte anteriore presenta il collo caratteristico di Teresa Ripoll, che valorizza con eleganza le spalle e lo scollo con uno stile inconfondibile. La schiena sfoggia uno scollo a V, coperto fino alla linea del reggiseno, offrendo comfort e discrezione. Un delicato dettaglio floreale dipinto a mano sulla schiena aggiunge un tocco artigianale ed esclusivo, elevando il design con un’eleganza unica. Un abito aderente e a maniche corte, perfetto per distinguersi con stile in ogni occasione speciale.", 
        'ca': "Vestit confeccionat en crep blau cerúlea, amb un disseny net i sofisticat. La part davantera presenta el coll característic de Teresa Ripoll, que realça les espatlles i l’escot amb una elegància inconfusible. L’esquena mostra un escot en V, tapat fins a la línia del sostenidor, aportant comoditat i discreció. Un delicat detall de flor pintada a mà a l’esquena afegeix un toc artesanal i exclusiu, elevant el disseny amb una finor única. Un vestit entallat i de màniga curta, perfecte per destacar amb estil en qualsevol ocasió especial.",
    },
    '3814': {
        'en': "Set featuring a jacket and dress crafted in steel-colored mikado sablé, an elegant and sophisticated fabric. The petrol-colored bias detailing defines and enhances the silhouette, adding a modern and contemporary touch to the design. The structured jacket with three-quarter sleeves balances and elevates the look, while the fitted dress flatters the figure with clean, refined lines. A perfect combination for special events where distinction and good taste take center stage.",
        'es': "Conjunto de chaqueta y vestido confeccionado en mikado sablé color acero, un tejido elegante y sofisticado. Los bieses en color petróleo definen y realzan la silueta, aportando un toque moderno y actual al diseño. La chaqueta estructurada de manga tres cuartos completa el look con equilibrio y sofisticación, mientras que el vestido entallado estiliza la figura con líneas limpias y depuradas. Una combinación perfecta para eventos especiales donde la distinción y el buen gusto son protagonistas.",
        'fr': "",
        'it': "Completo composto da giacca e abito realizzato in micado sablé color acciaio, un tessuto elegante e sofisticato. I profili color petrolio definiscono e valorizzano la silhouette, aggiungendo un tocco moderno e contemporaneo al design. La giacca strutturata con maniche a tre quarti dona equilibrio e raffinatezza al look, mentre l’abito aderente slancia la figura con linee pulite e raffinate. Una combinazione perfetta per eventi speciali dove la distinzione e il buon gusto sono protagonisti.", 
        'ca': "Conjunt de jaqueta i vestit confeccionat en micado sablé color acer, un teixit elegant i sofisticat. Els biaixos en color petroli defineixen i realcen la silueta, aportant un toc modern i contemporani al disseny. La jaqueta estructurada amb màniga de tres quarts equilibra i eleva el conjunt, mentre que el vestit entallat estilitza la figura amb línies netes i depurades. Una combinació perfecta per a esdeveniments especials on la distinció i el bon gust són protagonistes.",
    },
    '3815': {
        'en': "Dress crafted in steel-colored mikado sablé, featuring an elegant and fluid design. The V-neckline is enhanced with delicate jewel buttons, adding a sophisticated and exclusive touch. The three-quarter sleeves with turned-up cuffs balance the look, while the asymmetrical hemline, longer at the back, adds movement and dynamism to the design. A refined and modern piece, perfect for special occasions where distinction and style are essential.",
        'es': "Vestido confeccionado en mikado sablé color acero, con un diseño elegante y fluido. El escote en V se realza con un delicado detalle de botones joya, aportando un toque sofisticado y exclusivo. Las mangas tres cuartos con puño vuelto equilibran el look, mientras que el corte asimétrico de la falda, más largo en la parte trasera, aporta movimiento y dinamismo al diseño. Una pieza refinada y moderna, ideal para ocasiones especiales donde la distinción y el estilo son imprescindibles.",
        'fr': "",
        'it': "Abito realizzato in micado sablé color acciaio, con un design elegante e fluido. Lo scollo a V è impreziosito da delicati bottoni gioiello, che aggiungono un tocco sofisticato ed esclusivo. Le maniche a tre quarti con polsino risvoltato equilibrano il look, mentre l’orlo asimmetrico, più lungo nella parte posteriore, dona movimento e dinamismo al design. Un capo raffinato e moderno, ideale per occasioni speciali dove la distinzione e lo stile sono imprescindibili.", 
        'ca': "Vestit confeccionat en micado sablé color acer, amb un disseny elegant i fluid. L’escot en V es realça amb un delicat detall de botons joia, aportant un toc sofisticat i exclusiu. Les mànigues de tres quarts amb puny girat equilibren el conjunt, mentre que el tall asimètric de la faldilla, més llarg a la part posterior, afegeix moviment i dinamisme al disseny. Una peça refinada i moderna, ideal per a ocasions especials on la distinció i l’estil són imprescindibles.",
    },
    '3816': {
        'en': "Dress inspired by the elegance of the 1960s, crafted in steel-colored mikado sablé with lime green details that bring freshness and originality. The shirt collar and contrasting cuffs emphasize the retro style, while the matching covered buttons complete the design with subtlety. The midi flared skirt enhances the silhouette with movement and balance, creating a sophisticated and timeless look, perfect for standing out on any special occasion.",
        'es': "Vestido inspirado en la elegancia de los años 60, confeccionado en mikado sablé color acero con detalles en verde limaque aportan frescura y originalidad. El cuello camisero y los puños contrastados resaltan el estilo retro, mientras que los botones forrados en el mismo tono completan el diseño con sutileza. La falda de vuelo midi realza la silueta con movimiento y equilibrio, creando un look sofisticado y atemporal, perfecto para destacar en cualquier ocasión especial.",
        'fr': "",
        'it': "Abito ispirato all’eleganza degli anni '60, realizzato in micado sablé color acciaio con dettagli in verde lime che donano freschezza e originalità. Il colletto a camicia e i polsini a contrasto esaltano lo stile retrò, mentre i bottoni rivestiti nello stesso tono completano il design con delicatezza. La gonna midi svasata valorizza la silhouette con movimento ed equilibrio, creando un look sofisticato e senza tempo, perfetto per distinguersi in ogni occasione speciale.", 
        'ca': "Vestit inspirat en l’elegància dels anys 60, confeccionat en micado sablé color acer amb detalls en verd llima que aporten frescor i originalitat. El coll camisero i els punys contrastats ressalten l’estil retro, mentre que els botons folrats en el mateix to completen el disseny amb subtilesa. La faldilla midi de vol realça la silueta amb moviment i equilibri, creant un look sofisticat i atemporal, perfecte per destacar en qualsevol ocasió especial.",
    },
    '3817': {
        'en': "Dress crafted in lime green mikado sablé, featuring an elegant and timeless design. The Teresa Ripoll signature neckline, a hallmark of the brand, opens into a V-shape at the back, creating an evocative back that adds sensuality while maintaining discretion, as it subtly covers the bra line with an elegant placket. The midi flared skirt and three-quarter sleeves balance the ensemble, enhancing the figure and adding movement and sophistication. A perfect piece to stand out at any special occasion with refined style.",
        'es': "Vestido confeccionado en mikado sablé verde lima, con un diseño elegante y atemporal. El cuello Teresa Ripoll, característico de la marca, se abre en la espalda en forma de V, creando una espalda evocadora que aporta sensualidad, sin perder la discreción, ya que cubre la línea del sujetador con una tapeta sutil y elegante. La falda de vuelo midi y las mangas tres cuartos equilibran el conjunto, estilizando la figura y aportando movimiento y sofisticación. Una pieza perfecta para destacar en cualquier ocasión especial con un estilo refinado.",
        'fr': "",
        'it': "Abito realizzato in micado sablé verde lime, con un design elegante e senza tempo. Il collo Teresa Ripoll, caratteristico del marchio, si apre sulla schiena a forma di V, creando una schiena evocativa che dona sensualità mantenendo la discrezione, poiché copre la linea del reggiseno con una patta sottile ed elegante. La gonna midi svasata e le maniche a tre quarti bilanciano il capo, valorizzando la figura e aggiungendo movimento e raffinatezza. Un capo perfetto per distinguersi in ogni occasione speciale con uno stile raffinato.", 
        'ca': "Vestit confeccionat en micado sablé verd llima, amb un disseny elegant i atemporal. El coll Teresa Ripoll, característic de la marca, s’obre a l’esquena en forma de V, creant una esquena evocadora que aporta sensualitat, sense perdre la discreció, ja que cobreix la línia del sostenidor amb una tapeta subtil i elegant. La faldilla de vol midi i les mànigues de tres quarts equilibren el conjunt, realçant la figura i afegint moviment i sofisticació. Una peça perfecta per destacar en qualsevol ocasió especial amb un estil refinat.",
    },
    '3818': {
        'en': "Set featuring a jacket and dress crafted in piqué mikado with a vibrant combination of petrol and coral. The structured jacket with three-quarter sleeves showcases lapels and coral accents, adding contrast and dynamism to the design. The sleeveless fitted dress, with geometric panels in matching tones, enhances the silhouette and creates a modern and striking visual effect. A sophisticated set full of personality, ideal for events where elegance and color take center stage.",
        'es': "Conjunto de chaqueta y vestido confeccionado en piqué mikado en una combinación vibrante de petróleo y coral. La chaqueta estructurada con mangas tres cuartos presenta solapas y detalles en color coral que aportan contraste y dinamismo al diseño. El vestido entallado sin mangas, con paneles geométricos en los mismos tonos, estiliza la figura y crea un efecto visual moderno y llamativo. Un conjunto sofisticado y lleno de personalidad, ideal para eventos donde la elegancia y el colorson protagonistas.",
        'fr': "",
        'it': "Completo composto da giacca e abito realizzato in piqué mikado, in una vibrante combinazione di petrolio e corallo. La giacca strutturata con maniche a tre quarti presenta risvolti e dettagli in corallo che aggiungono contrasto e dinamismo al design. L’abito aderente senza maniche, con pannelli geometrici negli stessi toni, valorizza la silhouette e crea un effetto visivo moderno e accattivante. Un completo sofisticato e pieno di personalità, ideale per eventi dove eleganza e colore sono protagonisti.", 
        'ca': "Conjunt de jaqueta i vestit confeccionat en piqué micado amb una vibrant combinació de petroli i coral. La jaqueta estructurada amb mànigues de tres quarts presenta solapes i detalls en coral que aporten contrast i dinamisme al disseny. El vestit entallat sense mànigues, amb panells geomètrics en els mateixos tons, estilitza la silueta i crea un efecte visual modern i cridaner. Un conjunt sofisticat i ple de personalitat, ideal per a esdeveniments on l’elegància i el color són protagonistes.",
    },
    '3819': {
        'en': "Long dress in apple green crepe, combining matte and shiny finishes for a sophisticated visual effect. The elegant and minimalist chimney collar gently dips into a V-shape at the back, adding a subtle and delicate detail. The draped waist enhances and flatters the figure, adapting perfectly to all sizes with comfort and elegance. The flowing skirt falls gracefully, creating a balanced and refined silhouette. An ideal choice to stand out with distinction at any special event.",
        'es': "Vestido largo en crepé verde manzana, que combina acabados mate y brillo para un efecto visual sofisticado. El cuello chimenea, elegante y minimalista, desciende ligeramente en V en la espalda, aportando un detalle sutil y delicado. El drapeado en la cintura estiliza y favorece la figura, adaptándose a todas las tallas con comodidad y elegancia. La falda fluida cae con movimiento, creando una silueta equilibrada y refinada. Una opción ideal para lucir con distinción en cualquier evento especial.",
        'fr': "",
        'it': "Abito lungo in crêpe verde mela, che combina finiture opache e lucide per un effetto visivo sofisticato. L’elegante e minimalista collo a camino scende delicatamente in una V sulla schiena, aggiungendo un dettaglio sottile e delicato. Il drappeggio in vita valorizza e slancia la figura, adattandosi perfettamente a tutte le taglie con comfort ed eleganza. La gonna fluida cade con grazia, creando una silhouette equilibrata e raffinata. Una scelta ideale per distinguersi con classe in ogni evento speciale.", 
        'ca': "Vestit llarg en crep verd poma, que combina acabats mate i brillant per crear un efecte visual sofisticat. El coll xemeneia, elegant i minimalista, baixa lleugerament en V a l’esquena, afegint un detall subtil i delicat. El drapejat a la cintura realça i afavoreix la figura, adaptant-se còmodament a totes les talles amb elegància. La faldilla fluida cau amb moviment, creant una silueta equilibrada i refinada. Una elecció ideal per destacar amb distinció en qualsevol esdeveniment especial.",
    },
    '3820': {
        'en': "Long dress crafted in petrol crepe, featuring a sophisticated and fluid design. The Teresa Ripoll pleated neckline, combined with a subtle draping, enhances the neckline with elegance and distinction. The flowing sleeves, with their wide cut, add movement and dynamism, creating an ethereal and delicate silhouette. The side slit in the skirt elongates the figure and adds a modern and sensual touch. A perfect piece to stand out with elegance at special occasions.",
        'es': "Vestido largo confeccionado en crepé petróleo, con un diseño sofisticado y fluido. El cuello con pliegues Teresa Ripoll, acompañado de un ligero drapeado, realza el escote con elegancia y distinción. Las mangas fluidas, de corte amplio, aportan movimiento y dinamismo, creando una silueta etérea y delicada. La abertura lateral en la falda estiliza la figura y añade un toque moderno y sensual. Una pieza perfecta para destacar con elegancia en ocasiones especiales.",
        'fr': "",
        'it': "Abito lungo realizzato in crêpe petrolio, con un design sofisticato e fluido. Il collo con pieghe Teresa Ripoll, abbinato a un leggero drappeggio, valorizza lo scollo con eleganza e distinzione. Le maniche fluide, dal taglio ampio, donano movimento e dinamismo, creando una silhouette eterea e delicata. Lo spacco laterale nella gonna slancia la figura e aggiunge un tocco moderno e sensuale. Un capo perfetto per distinguersi con eleganza nelle occasioni speciali.", 
        'ca': "Vestit llarg confeccionat en crep petroli, amb un disseny sofisticat i fluid. El coll amb plecs Teresa Ripoll, combinat amb un subtil drapejat, realça l’escot amb elegància i distinció. Les mànigues fluids, de tall ample, aporten moviment i dinamisme, creant una silueta etèria i delicada. L’obertura lateral a la faldilla estilitza la figura i afegeix un toc modern i sensual. Una peça perfecta per destacar amb elegància en esdeveniments especials.",
    },
    '3821': {
        'en': "Dress crafted in petrol-colored mikado sablé, featuring the Teresa Ripoll neckline that descends into a V at the back, creating a sophisticated and elegant design. The Japanese-cut sleeves, accented with natural marabou feather cuffs, add a luxurious and exclusive touch. The peplum at the waist defines and enhances the silhouette with subtle elegance. A perfect piece for special occasions, where the petrol hue and refined details combine for a unique and distinguished look.",
        'es': "Vestido confeccionado en mikado sablé color petróleo, con un escote Teresa Ripoll que desciende en V en la espalda, creando un diseño sofisticado y elegante. Las mangas con corte japonés, adornadas con puños de pluma natural de marabú, aportan un toque lujoso y exclusivo. El peplum en la cintura realza y define la figura con sutileza, estilizando la silueta femenina. Una pieza ideal para ocasiones especiales, donde el color petróleo y los detalles refinados se combinan en un look único y distinguido.",
        'fr': "",
        'it': "Abito realizzato in mikado sablé color petrolio, con il collo Teresa Ripoll che scende in una V sulla schiena, creando un design sofisticato ed elegante. Le maniche con taglio giapponese, impreziosite da polsini in piume naturali di marabù, aggiungono un tocco lussuoso ed esclusivo. Il peplum in vita slancia e valorizza la silhouette con grazia. Un capo perfetto per occasioni speciali, dove il colore petrolio e i dettagli raffinati si fondono in un look unico e distintivo.", 
        'ca': "Vestit confeccionat en micado sablé color petroli, amb el coll Teresa Ripoll que baixa en V a l’esquena, creant un disseny sofisticat i elegant. Les mànigues de tall japonès, acabades amb punys de ploma natural de marabú, afegeixen un toc luxós i exclusiu. El peplum a la cintura realça i defineix la silueta amb subtilesa. Una peça ideal per a ocasions especials, on el color petroli i els detalls refinats es fusionen en un look únic i distingit.",
    },
    '3822': {
        'en': "Dress crafted in brocade fabric with blue and petrol tones, adding texture and depth to the design. The enveloping neckline in petrol piqué-mikado features cuts at arm level, allowing for comfortable and fluid movement, and finishes at the middle of the back with pleats forming a delicate bow. The midi flared skirt enhances the silhouette with its elegant and feminine structure, making it perfect for standing out at special occasions with a modern and sophisticated look.",
        'es': "Vestido confeccionado en tejido brocado en tonos azules y petróleo, que aporta textura y profundidad al diseño. El cuello envolvente en piqué-mikado color petróleo presenta cortes a la altura del brazo, permitiendo un movimiento cómodo y fluido, y termina en el centro de la espalda con pliegues que forman un delicado lazo. La falda de vuelo midi estiliza la silueta con su estructura elegante y femenina, ideal para destacar en ocasiones especiales con un look moderno y sofisticado.",
        'fr': "",
        'it': "Abito realizzato in tessuto broccato con toni blu e petrolio, che dona al design una texture ricca e profonda. Il collo avvolgente in piqué-mikado color petrolio presenta aperture all'altezza del braccio, permettendo un movimento comodo e fluido, e termina al centro della schiena con pieghe che formano un delicato fiocco. La gonna midi svasata valorizza la silhouette con una struttura elegante e femminile, perfetta per distinguersi in occasioni speciali con uno stile moderno e sofisticato.", 
        'ca': "Vestit confeccionat en teixit brocat en tons blaus i petroli, que aporta textura i profunditat al disseny. El coll envolventen piqué-mikado color petroli presenta talls a l’alçada del braç, permetent un moviment còmode i fluid, i acaba al mig de l’esquena amb plecs que formen un delicat llaç. La faldilla de vol midi realça la silueta amb una estructura elegant i femenina, ideal per destacar en ocasions especials amb un look modern i sofisticat.",
    },
    '3823': {
        'en': "Set consisting of a jacket and dress made in brocade with petrol and blue tones, combined with electric blue mikado, creating a sophisticated contrast. The jacket features flared cuff sleeves, adding fluidity to the design. The dress, with raglan sleeves and a mikado bodice, elegantly enhances the silhouette, while the brocade skirt adds texture and depth. A bow detail at the hip provides a delicate and feminine touch. An ideal ensemble to stand out with class at special occasions.",
        'es': "Conjunto de chaqueta y vestido confeccionado en brocado en tonos petróleo y azules, combinado con mikado azul eléctrico que aporta un contraste sofisticado. La chaqueta presenta mangas con puño acampanado, añadiendo fluidez al diseño. El vestido, con manga ranglán y cuerpo en mikado, estiliza la silueta con elegancia, mientras que la falda en brocadoañade textura y profundidad. Un lazo a la altura de la cadera aporta un toque delicado y femenino. Un conjunto ideal para destacar con clase en ocasiones especiales.",
        'fr': "",
        'it': "Completo composto da giacca e abito realizzato in broccato nei toni del petrolio e blu, abbinato a mikado blu elettrico, che crea un contrasto sofisticato. La giacca presenta maniche con polsino svasato, aggiungendo fluidità al design. L’abito, con maniche raglan e corpetto in mikado, valorizza elegantemente la silhouette, mentre la gonna in broccatodona profondità e texture. Un fiocco all’altezza dei fianchi aggiunge un tocco delicato e femminile. Un completo perfetto per distinguersi con classe in occasioni speciali.", 
        'ca': "Conjunt de jaqueta i vestit confeccionat en brocat en tons petroli i blaus, combinat amb micado blau elèctric que aporta un contrast sofisticat. La jaqueta destaca per les mànigues amb puny acampanat, afegint fluïdesa al disseny. El vestit, amb màniga ranglan i cos en micado, estilitza la silueta amb elegància, mentre que la faldilla en brocat aporta textura i profunditat. Un llacet a l’alçada del maluc afegeix un toc delicat i femení. Un conjunt ideal per destacar amb classe en ocasions especials.",
    },
    '3824': {
        'en': "Elegant dress combining electric blue mikado with brocade in petrol and blue tones. The design features a delicate side bow that defines and enhances the waist, adding a feminine and sophisticated touch. The fitted skirt and combination of fabrics create a modern and elegant silhouette, perfect for standing out at any special event.",
        'es': "Vestido elegante que combina mikado azul eléctrico con brocado en tonos petróleo y azules. El diseño presenta un delicado lazo lateral que estiliza y realza la cintura, aportando un toque femenino y sofisticado. La falda ajustada y la combinación de tejidos crean una silueta moderna y elegante, ideal para destacar en cualquier evento especial.",
        'fr': "",
        'it': "Abito elegante che combina mikado blu elettrico con broccato nei toni del petrolio e blu. Il design presenta un delicato fiocco laterale che valorizza e definisce la vita, aggiungendo un tocco femminile e sofisticato. La gonna aderente e la combinazione di tessuti creano una silhouette moderna ed elegante, perfetta per distinguersi in qualsiasi evento speciale.", 
        'ca': "Vestit elegant que combina micado blau elèctric amb brocat en tons petroli i blaus. El disseny presenta un delicat llaç lateral que defineix i realça la cintura, aportant un toc femení i sofisticat. La faldilla ajustada i la combinació de teixits creen una silueta moderna i elegant, perfecta per destacar en qualsevol esdeveniment especial.",
    },
    '3825': {
        'en': "Dress featuring the signature Teresa Ripoll neckline, which includes an elegant back opening. The electric blue mikado bodice showcases a peplum that enhances the waist and a hand-painted floral detail for an artisanal and exclusive touch. The flowing chiffon skirt in the same tone adds lightness and movement, creating a feminine and sophisticated silhouette.",
        'es': "Vestido con el característico cuello Teresa Ripoll, que incorpora una elegante abertura en la espalda. El cuerpo en mikado azul eléctrico presenta un peplum que estiliza la cintura y un detalle de flor pintada a mano que añade un toque artesanal y exclusivo. La falda fluida de gasa en el mismo tono aporta ligereza y movimiento al diseño, creando una silueta femenina y sofisticada.",
        'fr': "",
        'it': "Abito con il caratteristico collo Teresa Ripoll, che include un’elegante apertura sulla schiena. Il corpetto in mikado blu elettrico presenta un peplum che valorizza il punto vita e un dettaglio floreale dipinto a mano, che aggiunge un tocco artigianale ed esclusivo. La gonna fluida in chiffon dello stesso tono dona leggerezza e movimento, creando una silhouette femminile e sofisticata.", 
        'ca': "Vestit amb el característic coll Teresa Ripoll, que incorpora una elegant obertura a l’esquena. El cos en micado blau elèctric destaca per un peplum que realça la cintura i un detall de flor pintada a mà, que afegeix un toc artesanal i exclusiu. La faldilla fluida de gasa en el mateix to aporta lleugeresa i moviment, creant una silueta femenina i sofisticada.",
    },
    '3826': {
        'en': "Dress crafted in petrol-colored mikado sablé, providing structure and a subtle sheen. The design features pleated sleeves that mirror the draped detail at the hip, creating harmony and enhancing the silhouette. A sophisticated and original piece, perfect for standing out elegantly at any event.",
        'es': "Vestido confeccionado en mikado sablé color petróleo, que aporta estructura y un brillo sutil. El diseño presenta mangas con pliegues que imitan el detalle drapeado de la cadera, creando armonía y estilizando la silueta. Una pieza sofisticada y original, ideal para destacar con elegancia en cualquier evento.",
        'fr': "",
        'it': "Abito realizzato in mikado sablé color petrolio, che dona struttura e una lucentezza delicata. Il design presenta maniche con pieghe che richiamano il drappeggio sul fianco, creando armonia e valorizzando la silhouette. Un capo sofisticato e originale, ideale per distinguersi con eleganza in ogni occasione.", 
        'ca': "Vestit confeccionat en micado sablé color petroli, que aporta estructura i una lluentor subtil. El disseny presenta mànigues amb plecs que imiten el drapejat del maluc, creant harmonia i estilitzant la silueta. Una peça sofisticada i original, perfecta per destacar amb elegància en qualsevol esdeveniment.",
    },
    '3827': {
        'en': "Dress in piqué-mikado fabric in St. Gillian blue. The signature Teresa Ripoll pleated neckline enhances the figure and exudes elegance. The waist detail, mirroring the neckline design, accentuates the silhouette. The A-line skirt adds movement and flow, emphasizing the waist and creating a harmonious and sophisticated piece, perfect for special occasions.",
        'es': "Vestido en piqué-mikado color azul St. Gillian. El característico cuello Teresa Ripoll con pliegues estiliza la figura y aporta elegancia. El detalle en la cintura, que imita el diseño del cuello, remarca la silueta. La falda de corte evaséproporciona movimiento y fluidez, destacando la cintura y completando una pieza armoniosa y sofisticada, ideal para eventos especiales.",
        'fr': "",
        'it': "Abito realizzato in piqué-mikado color blu St. Gillian. Il caratteristico collo Teresa Ripoll con pieghe valorizza la figura e dona eleganza. Il dettaglio in vita, che richiama il design del collo, sottolinea la silhouette. La gonna a taglio evasé dona movimento e fluidità, enfatizzando la vita e completando un capo armonioso e sofisticato, ideale per occasioni speciali.", 
        'ca': "Vestit en piqué-mikado color blau St. Gillian. El característic coll Teresa Ripoll amb plecs estilitza la figura i aporta elegància. El detall a la cintura, que imita el disseny del coll, ressalta la silueta. La faldilla de tall evasé proporciona moviment i fluïdesa, remarcant la cintura i completant una peça harmoniosa i sofisticada, perfecta per a esdeveniments especials.",
    },
    '3828': {
        'en': "Dress in bougainvillea-colored mikado sablé, accented with fabric-covered buttons in pink for a subtle contrast. The 1960s-inspired design exudes sophisticated retro charm. The petal details on the shoulders form delicate cap sleeves, completing an elegant and feminine silhouette.",
        'es': "Vestido en mikado sablé color buganvilla, combinado con botones forrados en rosa que añaden un delicado contraste. Su diseño de inspiración años 60 aporta un aire retro sofisticado. Los detalles de pétalos sobre los hombros crean un efecto elegante de media manga, completando una pieza estilizada y femenina.",
        'fr': "",
        'it': "Abito in mikado sablé color bouganville, combinato con bottoni rivestiti in tessuto rosa che aggiungono un delicato contrasto. Il design ispirato agli anni '60 dona un fascino retrò sofisticato. I dettagli a petali sulle spalle creano un effetto raffinato di mezza manica, completando una silhouette elegante e femminile.", 
        'ca': "Vestit en mikado sablé color bugainvília, combinat amb botons folrats en rosa que aporten un contrast subtil. El disseny d'inspiració anys 60 transmet un encant retro sofisticat. Els detalls de pètals sobre les espatlles creen un efecte elegant de mitja màniga, completant una silueta estilitzada i femenina.",
    },
    '3829': {
        'en': "Long dress crafted in matte bougainvillea crepe, featuring the signature Teresa Ripoll shiny neckline that opens into a V at the back, yet closed with a placket. Elegant button detailing along the back adds a touch of refinement.",
        'es': "Vestido largo confeccionado en crepe buganvilla mate, con el cuello brillante característico Teresa Ripoll que se abre en V en la espalda, pero cerrado con una tapeta. Detalle de botones a lo largo de la espalda que aporta sofisticación y elegancia.",
        'fr': "",
        'it': "Abito lungo realizzato in crepe bouganville opaco, con il collo lucido caratteristico di Teresa Ripoll che si apre a V sulla schiena ma è chiuso con una pattina. Dettaglio di bottoni lungo la schiena, che dona un tocco sofisticato ed elegante.", 
        'ca': "Vestit llarg confeccionat en crep buganvília mat, amb el coll brillant característic Teresa Ripoll que s'obre en V a l'esquena, però tancat amb una tapeta. Detall de botons al llarg de l'esquena que afegeix sofisticació i elegància.",
    },
    '3830': {
        'en': "Midi-length dress in red crepe, elegant and sophisticated, featuring princess seams and a diagonal cut at the hip that enhance the silhouette. The draped waist, combined with these design details, accentuates and flatters all body typesnaturally. The 3/4-length sleeves include a jewel button, adding a touch of distinction and style. A perfect design to stand out with class and confidence at any special occasion.",
        'es': "Vestido largo midi en crepe rojo, elegante y sofisticado, con costadillos y un corte diagonal a la altura de la caderaque estilizan la silueta. El drapeado en la cintura, en conjunto con estos detalles de diseño, realza y favorece todas las figuras con gran naturalidad. La manga 3/4 presenta un botón joya que aporta un toque de distinción y estilo. Un diseño ideal para destacar con clase y confianza en cualquier ocasión especial.",
        'fr': "",
        'it': "Abito midi in crêpe rosso, elegante e sofisticato, con cuciture modellanti e un taglio diagonale all'altezza dei fianchiche valorizzano la silhouette. Il drappeggio in vita, unito a questi dettagli di design, esalta e dona armonia a tutte le figure con naturalezza. Le maniche a 3/4 presentano un bottone gioiello che aggiunge un tocco di distinzione e stile. Un design perfetto per risaltare con classe e sicurezza in qualsiasi occasione speciale.", 
        'ca': "Vestit midi llarg en crep vermell, elegant i sofisticat, amb costadillos i un tall diagonal a l’alçada del maluc que estilitzen la silueta. El drapejat a la cintura, combinat amb aquests detalls de disseny, realça i afavoreix totes les figures de manera natural. Les mànigues 3/4 incorporen un botó joia que aporta un toc de distinció i estil. Un disseny perfecte per destacar amb classe i confiança en qualsevol ocasió especial.",
    },
    '3831': {
        'en': "Long coat-style dress, crafted in coral crepe that exudes elegance and sophistication. The signature Teresa Ripoll crossover collar delicately frames the neckline, while the hand-painted flower detail at the waist adds an artisanal and exclusive touch. The sleeves with cuffs provide an extra touch of elegance, completing a fluid and timeless design that enhances the silhouette with style and distinction.",
        'es': "Vestit llarg en forma d'abric, confeccionat en crep color coral que aporta elegància i sofisticació. El coll Teresa Ripoll, creuat al davant, emmarca delicadament l’escot, mentre que la flor pintada a mà a la cintura afegeix un toc artesanal i exclusiu. Les mànigues amb punys aporten un detall extra d'elegància, completant un disseny fluid i atemporal que ressalta la figura amb estil i distinció.",
        'fr': "",
        'it': "Abito lungo a forma di cappotto, realizzato in crêpe color corallo che emana eleganza e raffinatezza. Il colletto incrociato Teresa Ripoll, posto sul davanti, incornicia delicatamente lo scollo, mentre il dettaglio del fiore dipinto a mano sulla cintura aggiunge un tocco artigianale ed esclusivo. Le maniche con polsini conferiscono un tocco extra di eleganza, completando un design fluido e senza tempo che valorizza la silhouette con stile e distinzione.", 
        'ca': "Vestit llarg en forma d'abric, confeccionat en crepe color coral que aporta elegància i sofisticació. El coll Teresa Ripoll, creuat al davant, emmarca delicadament l'escot, mentre que la flor pintada a mà a la cintura afegeix un toc artesanal i exclusiu. Les mànigues amb punys aporten un detall d'elegància addicional, completant un disseny fluid i atemporal que realça la figura amb estil i distinció.",
    },
    '3832': {
        'en': "Dress in coral crepe that gives the appearance of a two-piece ensemble. The top features the signature Teresa Ripoll neckline and an asymmetrical back drape, adding movement and sophistication. The straight skirt flatters the figure, while the back incorporates a pleated accordion gusset, replacing the traditional slit and providing ample fabric for comfortable movement, without compromising the elegance of the silhouette. Perfect for special events.",
        'es': "Vestido en crepe coral que simula un conjunto de dos piezas. La parte superior presenta el característico cuello Teresa Ripoll i una caída asimétrica en la espalda, aportando movimiento y sofisticación. La falda recta estiliza la figura, mientras que en la parte trasera destaca un fuelle plegado en acordeón, que sustituye el clásico corte y permite tener el tejido suficiente para caminar con comodidad, sin perder la elegancia de la silueta. Ideal para eventos especiales.",
        'fr': "",
        'it': "Abito in crepe corallo che simula un completo a due pezzi. La parte superiore presenta il caratteristico collo Teresa Ripoll e una caduta asimmetrica nella parte posteriore, aggiungendo movimento e raffinatezza. La gonna dritta valorizza la figura, mentre nella parte posteriore si distingue un soffietto plissettato a fisarmonica, che sostituisce il classico spacco e permette di avere abbondante tessuto per camminare comodamente, senza rinunciare all'eleganza della silhouette. Ideale per eventi speciali.", 
        'ca': "Vestit en crep coral que simula un conjunt de dues peces. La part superior presenta el característic coll Teresa Ripoll i una caiguda asimètrica a l'esquena, aportant moviment i sofisticació. La faldilla recta estilitza la figura, mentre que a la part del darrere destaca un folre plegat en acordió, que substitueix el clàssic tall i permet tenir prou teixit per caminar còmodament, sense perdre l’elegància de la silueta. Ideal per a esdeveniments especials.",
    },
    '3833': {
        'en': "Dress made in coral pink crepe. The matte-finished body contrasts with the smoking-inspired collar and cuffs, both in shiny fabric. Geometric cuts below the waist create a bodice shape, enhancing the silhouette. The flared skirt adds movement and elegance, making it ideal for any special occasion.",
        'es': "Vestido confeccionado en crepe color coral rosa. El cuerpo en acabado mate contrasta con el cuello inspirado en smoking y los puños, ambos en brillo. Los cortes geométricos bajo la cintura crean una forma de corpiño, realzando la silueta. La falda, con vuelo, aporta movimiento y elegancia, ideal para cualquier ocasión especial.",
        'fr': "",
        'it': "Abito realizzato in crêpe color corallo rosa. Il corpo con finitura opaca contrasta con il colletto ispirato allo smoking e i polsini, entrambi in tessuto lucido. I tagli geometrici sotto la vita creano una forma a corsetto, valorizzando la silhouette. La gonna svasata dona movimento ed eleganza, perfetta per ogni occasione speciale.", 
        'ca': "Vestit confeccionat en crep color coral rosa. El cos en acabat mat contrasta amb el coll inspirat en esmòquing i els punys, tots dos en brillant. Els talls geomètrics sota la cintura creen una forma de cosset, realçant la silueta. La faldilla, amb vol, aporta moviment i elegància, perfecta per a qualsevol ocasió especial.",
    },
    '3834': {
        'en': "Dress crafted in brocade fabric with an elegant combination of peach and beige tones. The Teresa Ripoll collar, crossed at the front, adds a sophisticated touch. A spectacular handmade flower, created with a combination of fabrics, enhances the waist. The flared skirt provides movement and elegance, making it a perfect choice for special occasions.",
        'es': "Vestido confeccionado en tejido brocado con una elegante combinación de tonos melocotón y beige. El cuello Teresa Ripoll, cruzado en la parte delantera, aporta un toque sofisticado. Destaca la espectacular flor artesanal elaborada con una combinación de tejidos que realza la cintura. La falda de vuelo añade movimiento y elegancia, convirtiéndolo en una pieza ideal para ocasiones especiales.",
        'fr': "",
        'it': "Abito realizzato in tessuto broccato con una raffinata combinazione di toni pesca e beige. Il colletto Teresa Ripoll, incrociato nella parte anteriore, dona un tocco sofisticato. Spicca lo spettacolare fiore artigianale, creato con una combinazione di tessuti, che valorizza il punto vita. La gonna svasata aggiunge movimento ed eleganza, rendendolo perfetto per occasioni speciali.", 
        'ca': "Vestit confeccionat en teixit brocat amb una elegant combinació de tons préssec i beix. El coll Teresa Ripoll, creuat a la part davantera, aporta un toc sofisticat. Destaca l’espectacular flor artesanal, feta amb una combinació de teixits, que realça la cintura. La faldilla amb vol afegeix moviment i elegància, ideal per a ocasions especials.",
    },
    '3835': {
        'en': "Mother-of-the-bride dress in peach and beige brocade, featuring decorative bias details that elongate the figure. The sleeveless design with a square neckline is complemented by a bolero-style jacket with short sleeves and shiny crepe cuffs, adding a touch of sophistication. Perfect for special occasions.",
        'es': "Vestido de madrina en brocado de tonos melocotón y beige, con detalles decorativos en bies que estilizan la figura. El diseño sin mangas y con escote cuadrado se completa con una chaqueta tipo torera de manga corta, con puños y acabados en crepé brillante que aportan un toque de sofisticación. Perfecto para ocasiones especiales.",
        'fr': "",
        'it': "Abito da cerimonia in broccato di tonalità pesca e beige, con dettagli decorativi in sbieco che slanciano la figura. Il design senza maniche e con scollo quadrato è completato da una giacca stile bolero con maniche corte e polsini in crêpe lucido, che aggiungono un tocco di raffinatezza. Perfetto per occasioni speciali.", 
        'ca': "Vestit de madrina en brocat de tons préssec i beix, amb biaixos decoratius que estilitzen la figura. El disseny sense mànigues i amb escot quadrat es completa amb una jaqueta tipus torera de màniga curta, amb punys i acabats en crepe lluent que aporten un toc de sofisticació. Perfecte per a esdeveniments especials.",
    },
    '3836': {
        'en': "Short dress in golden piqué-mikado fabric, featuring a classic neckline that enhances the figure and elongates the body. The fitted seams define the silhouette, while the 3/4 sleeves, decorated with cuffs thanks to a foldable design, add a touch of sophistication. Perfect for unique and unforgettable moments.",
        'es': "Vestido corto en tejido piqué-mikado dorado, con un cuello clásico que realza la figura y alarga visualmente el cuerpo. Las costuras entalladas definen la silueta, mientras que las mangas 3/4, decoradas con puños gracias a un diseño que permite darles la vuelta, aportan un toque de sofisticación. Perfecto para momentos únicos e inolvidables.",
        'fr': "",
        'it': "Abito corto in tessuto piqué-mikado dorato, con uno scollo classico che valorizza la figura e slancia il corpo. Le cuciture sagomate definiscono la silhouette, mentre le maniche a 3/4, decorate con polsini grazie a un design reversibile, aggiungono un tocco di raffinatezza. Perfetto per momenti unici e indimenticabili.", 
        'ca': "Vestit curt en color daurat elaborat amb teixit piqué-mikado, que destaca per un coll clàssic que realça la figura i allarga visualment el cos. Els costadets defineixen la silueta, mentre que les mànigues 3/4, decorades amb punys gràcies a un tall que permet donar-los la volta, aporten un toc de sofisticació. Ideal per a moments únics i inoblidables.",
    },
    '3837': {
        'en': "Dress with a bodice of white and silver paillettes, paired with a golden piqué-mikado skirt in a midi length. The design features short sleeves and a jacket-like bodice that defines and enhances the waist, while the A-line skirt adds movement and elegance. A unique piece for special occasions.",
        'es': "Vestido con cuerpo de paillette en tonos blanco y plata, combinado con una falda de tejido piqué-mikado en color dorado y largo midi. El diseño incluye manga corta y un cuerpo con efecto chaqueta que define y realza la cintura, mientras que la falda en forma de A aporta movimiento y elegancia. Una pieza única para momentos especiales.",
        'fr': "",
        'it': "Abito con corpetto in paillettes bianche e argento, abbinato a una gonna in piqué-mikado dorato di lunghezza midi. Il design presenta maniche corte e un corpetto effetto giacca che definisce e valorizza la vita, mentre la gonna a forma di A dona movimento ed eleganza. Un capo unico per occasioni speciali.", 
        'ca': "Vestit amb cos de paillette en tons blanc i plata, combinat amb una faldilla de teixit piqué-mikado en color daurat i llarg midi. El disseny inclou màniga curta i un cos amb efecte jaqueta que defineix i realça la cintura, mentre que la faldilla en forma de A aporta moviment i elegància. Una peça única per a moments especials.",
    },
    '3838': {
        'en': "A dress in apple green crafted from piqué-mikado fabric, featuring a midi flared skirt that adds movement and elegance. The design includes a pleated neckline and flap details that mimic pockets, elongating and flattering the silhouette. The 3/4 sleeves feature a slit that can be turned into a sophisticated cuff, providing a refined finish perfect for special moments.",
        'es': "Vestido en verde manzana confeccionado en tejido piqué-mikado, con una falda midi de vuelo que aporta movimiento y elegancia. El diseño incluye un cuello con pliegues y detalles de tapetas que simulan bolsillos, alargando y estilizando la silueta. Las mangas 3/4 cuentan con un corte que se puede voltear para crear un sofisticado puño, ofreciendo un acabado refinado ideal para momentos especiales.",
        'fr': "",
        'it': "Abito in verde mela realizzato in tessuto piqué-mikado, con una gonna midi svasata che dona movimento ed eleganza. Il design presenta uno scollo con pieghe e dettagli di patte che imitano tasche, slanciando e valorizzando la silhouette. Le maniche a 3/4 includono un taglio che può essere risvoltato per creare un polsino sofisticato, offrendo una finitura raffinata perfetta per momenti speciali.", 
        'ca': "Vestit en verd poma confeccionat en teixit piqué-mikado, amb una faldilla midí de vol que aporta moviment i elegància. El disseny incorpora un coll amb plecs i detalls de tapetes que simulen butxaques, allargant i estilitzant la figura. Les mànigues 3/4 inclouen un tall que es pot voltejar per crear un puny sofisticat, oferint un acabat refinat ideal per a moments especials.",
    },
    '3839': {
        'en': "A set consisting of a blouse and a long dress that exudes elegance and sophistication. The blouse is made from delicate French lace from Calais, featuring a silk organza collar in soft aqua blue tones for a refined touch. The dress, crafted from crepe in a strapless design, is long and straight, with an accordion pleat detail at the back of the skirt that allows for graceful movement. Perfect for formal and unforgettable occasions.",
        'es': "Conjunto compuesto por una blusa y un vestido largo que combina elegancia y sofisticación. La blusa está confeccionada en una delicada blonda francesa de Calais, con un cuello de organza de seda en tonos azul agua que aporta un toque refinado. El vestido, de crepé y diseño palabra de honor, es largo y recto, con un fuelle en forma de acordeón en la parte trasera de la falda que facilita el movimiento y añade fluidez. Perfecto para ocasiones formales e inolvidables.",
        'fr': "",
        'it': "Completo composto da una blusa e un abito lungo che sprigiona eleganza e raffinatezza. La blusa è realizzata in delicato pizzo francese di Calais, con un colletto in organza di seta in tonalità azzurro acqua che dona un tocco sofisticato. L’abito, in crepe con un design a spalle scoperte, è lungo e lineare, con un dettaglio a piega a fisarmonica nella parte posteriore della gonna che permette un movimento fluido. Perfetto per occasioni formali e indimenticabili.", 
        'ca': "Conjunt format per una blusa i un vestit llarg que combina elegància i sofisticació. La blusa està confeccionada amb una delicada blonda francesa de Calé, amb un coll d’organza de seda en tons blau aigua que aporta un toc refinat. El vestit, de crep i disseny paraula d’honor, és llarg i recte, amb un fuelle en forma d’acordió a la part posterior de la faldilla que facilita el moviment i afegeix fluïdesa. Perfecte per a ocasions formals i inoblidables.",
    },
    '3840': {
        'en': "A long dress featuring a structured bodice and collar in elegant silver mikado, offering sophistication and balance to the design. The skirt, made from natural silk chiffon, flows lightly, creating a delicate and airy movement. As an exclusive detail, it includes a hand-painted flower that adds a unique and refined artisanal touch. Perfect for special events where elegance and distinction are key.",
        'es': "Vestido largo con un cuerpo y cuello estructurado en un elegante mikado color plata, que aporta sofisticación y equilibrio al diseño. La falda, confeccionada en gasa de seda natural, fluye ligera, creando un movimiento delicado y vaporoso. Como detalle exclusivo, incorpora una flor pintada a mano que añade un toque artesanal único y refinado. Ideal para eventos especiales donde la elegancia y la distinción son clave.",
        'fr': "",
        'it': "Abito lungo con corpetto e colletto strutturati in elegante mikado color argento, che conferiscono sofisticazione ed equilibrio al design. La gonna, realizzata in chiffon di seta naturale, scorre leggera creando un movimento delicato e arioso. Come dettaglio esclusivo, include un fiore dipinto a mano che aggiunge un tocco artigianale unico e raffinato. Perfetto per eventi speciali dove eleganza e distinzione sono fondamentali.", 
        'ca': "Vestit llarg amb un cos i coll estructurat de mikado en un elegant color plata, que aporta sofisticació i equilibri al disseny. La faldilla, confeccionada en gassa de seda natural, flueix lleugera, creant un moviment delicat i vaporós. Com a detall exclusiu, incorpora una flor pintada a mà que afegeix un toc d’artesania única i refinada. Ideal per a esdeveniments especials on es busqui elegància i distinció.",
    },
    '3841': {
        'en': "A dress and jacket set in a soft stone color, designed to stand out with subtlety and elegance. The sleeveless dress features a Calais lace band at the waist, delicately enhancing the silhouette. The jacket, with structured lines and lightweight lace sleeves and hem, adds texture and fluidity to the ensemble. A perfect choice for special occasions with a distinctive touch.",
        'es': "Conjunto de vestido y chaqueta en un delicado color piedra, diseñado para destacar con sutileza y elegancia. El vestido sin mangas incluye una franja de encaje de Calais en la cintura que define la silueta con delicadeza. La chaqueta, con líneas estructuradas y acabada con mangas y faldón de encaje ligero, aporta textura y movimiento al conjunto. Una opción ideal para celebraciones especiales con un toque distintivo.",
        'fr': "",
        'it': "Completo di abito e giacca in un delicato colore pietra, progettato per distinguersi con sobrietà ed eleganza. L’abito senza maniche presenta una fascia in pizzo di Calais in vita, che valorizza delicatamente la silhouette. La giacca, con linee strutturate e maniche e orlo in pizzo leggero, aggiunge texture e movimento al completo. Una scelta perfetta per celebrazioni speciali con un tocco distintivo.", 
        'ca': "Conjunt de vestit i jaqueta en un delicat color pedra, dissenyat per destacar amb subtilesa i elegància. El vestit sense mànigues inclou una franja de blonda de Calais a la cintura que defineix la silueta amb delicadesa. La jaqueta, amb línies estructurades i acabada amb mànigues i faldó de blonda lleugera, aporta textura i moviment al conjunt. Una opció ideal per a celebracions especials amb un toc distintiu.",
    },
    '3842': {
        'en': "A set consisting of a blouse and a long dress in an elegant silver tone. The blouse, crafted from natural silk organza, stands out for its lightness and elegance, adding a delicate, airy touch to the ensemble. The strapless dress, made from piqué-mikado, features a central slit that brings movement and a modern feel. A perfect combination for occasions requiring distinction and refinement.",
        'es': "Conjunto formado por una blusa y un vestido largo en un sofisticado color plata. La blusa, confeccionada en organza de seda natural, destaca por su ligereza y elegancia, aportando un toque vaporoso al conjunto. El vestido, de diseño palabra de honor y elaborado en piqué-mikado, se completa con una abertura central que añade movimiento y modernidad. Una combinación perfecta para ocasiones que requieren distinción y delicadeza.",
        'fr': "",
        'it': "Completo composto da una blusa e un abito lungo in un elegante colore argento. La blusa, realizzata in organza di seta naturale, si distingue per la sua leggerezza ed eleganza, aggiungendo un tocco etereo al completo. L’abito senza spalline, in piqué-mikado, presenta uno spacco centrale che dona movimento e modernità. Una combinazione perfetta per occasioni che richiedono distinzione e raffinatezza.", 
        'ca': "Conjunt format per una blusa i un vestit llarg en un sofisticat color plata. La blusa, confeccionada en organza de seda natural, destaca per la seva lleugeresa i elegància, oferint un toc vaporós al conjunt. El vestit, de disseny paraula d’honor i elaborat en piqué-mikado, es completa amb un tall central que afegeix moviment i modernitat. Una combinació perfecta per a ocasions que requereixen distinció i delicadesa.",
    },
    '3843': {
        'en': "A dress and jacket set crafted in pink crepe, designed to exude elegance and delicacy. The sleeveless dress features an under-bust cut with pleats adorned with small rhinestones, adding a subtle touch of sparkle and sophistication. The jacket, which complements the ensemble, includes matching decorative pleats around the neckline, also embellished with rhinestones, achieving a perfect harmony between modernity and tradition. An exquisite choice to shine at special occasions.",
        'es': "Conjunto de vestido y chaqueta confeccionado en crepé rosa, diseñado para transmitir elegancia y delicadeza. El vestido, sin mangas, presenta un corte bajo el pecho con pliegues adornados con pequeños brillantitos, que aportan un toque sutil de luz y sofisticación. La chaqueta, que complementa el conjunto, incluye los mismos pliegues decorativos alrededor del cuello, también embellecidos con brillantitos, logrando una armonía perfecta entre modernidad y tradición. Una propuesta exquisita para brillar en ocasiones especiales.",
        'fr': "",
        'it': "Completo di abito e giacca realizzato in crêpe rosa, progettato per esprimere eleganza e delicatezza. L’abito senza maniche presenta un taglio sotto il seno con pieghe adornate da piccoli strass, che aggiungono un tocco sottile di luce e raffinatezza. La giacca, che completa il completo, include pieghe decorative coordinate intorno al collo, anch’esse impreziosite da strass, creando una perfetta armonia tra modernità e tradizione. Una proposta squisita per brillare in occasioni speciali.", 
        'ca': "Conjunt de vestit i jaqueta confeccionat en crep rosa, dissenyat per transmetre elegància i delicadesa. El vestit, sense mànigues, presenta un tall sota el pit amb plecs adornats amb petits brillantets, que aporten un toc subtil de llum i sofisticació. La jaqueta, que complementa el conjunt, inclou els mateixos plecs decoratius al voltant del coll, també embellits amb brillantets, oferint una harmonia perfecta entre modernitat i tradició. Una proposta exquisida per brillar en ocasions especials.",
    },
    '3844': {
        'en': "A special long dress crafted from matching-tone pink crepe and beadwork, creating a harmonious and elegant combination. The design features beadwork embellishments that accentuate the bodice, adding sparkle and a sophisticated visual effect. The skirt includes a side slit that enhances movement and introduces a modern touch. A perfect piece to shine confidently at exclusive events.",
        'es': "Vestido largo confeccionado en pedrería y crepé en un delicado tono rosa que crea una combinación armoniosa y elegante. El diseño destaca por las aplicaciones de pedrería que recorren el cuerpo, aportando brillo y un efecto visual sofisticado. La falda incorpora una abertura lateral que añade movimiento y un toque moderno al conjunto. Una pieza ideal para lucir con confianza en eventos exclusivos.",
        'fr': "",
        'it': "Abito lungo realizzato in crêpe e perline in una delicata tonalità rosa, che crea una combinazione armoniosa ed elegante. Il design si distingue per le applicazioni di perline che decorano il corpetto, donando luminosità ed un effetto visivo sofisticato. La gonna include uno spacco laterale che aggiunge movimento e un tocco moderno al completo. Un capo perfetto per brillare con sicurezza in eventi esclusivi.", 
        'ca': "Vestit llarg confeccionat en pedreria i crep en un delicat to rosa que crea una combinació harmoniosa i elegant. El disseny destaca per les aplicacions de pedreria que recorren el cos, aportant brillantor i un efecte visual sofisticat. La faldilla incorpora una obertura lateral que afegeix moviment i un toc modern al conjunt. Una peça ideal per a lluir amb confiança en esdeveniments exclusius.",
    },
    '3845': {
        'en': "A short dress crafted with paillettes in soft pink and white tones, adding sparkle and a sophisticated style. The chimney collar and pink crepe details, such as the godets that flare out in the skirt, create a harmonious contrast that adds texture and movement to the design. An ideal piece for events where elegance and personality are key.",
        'es': "Vestido corto confeccionado con paillettes en tonos rosa y blanco, que aporta brillo y un estilo sofisticado. El cuello chimenea y los detalles en crepé rosa, como los godets que se abren en la falda, crean un contraste armonioso que añade textura y movimiento al diseño. Una pieza ideal para eventos donde se quiera destacar con elegancia y personalidad.",
        'fr': "",
        'it': "Abito corto realizzato con paillettes in tonalità rosa e bianco, che dona luminosità e uno stile sofisticato. Il colletto a camino e i dettagli in crêpe rosa, come i godet che si aprono nella gonna, creano un contrasto armonioso che aggiunge texture e movimento al design. Un capo ideale per eventi in cui eleganza e personalità sono essenziali.", 
        'ca': "Vestit curt confeccionat amb paillette en tons rosa i blanc, que aporta brillantor i un estil sofisticat. El coll xemeneia i els detalls en crep rosa, com els godets que s’obren a la faldilla, creen un contrast harmoniós que afegeix textura i moviment al disseny. Una peça ideal per a esdeveniments on es vol destacar amb elegància i personalitat.",
    },
    '3846': {
        'en': "A dress crafted from pink mikado-sable fabric with a design that gives the visual effect of a coat-dress while being a single piece. The structured neckline with lapels and the hand-painted flower detail at the waist add sophistication and a unique artisanal touch. A refined design, perfect for standing out at special occasions with an original and exclusive style.",
        'es': "Vestido confeccionado en mikado-sable rosa con un diseño que crea el efecto visual de un abrigo-vestido, aunque es una sola pieza. El escote estructurado con solapas y el detalle de una flor pintada a mano en la cintura aportan sofisticación y un toque artesanal único. Un diseño refinado, ideal para destacar en ocasiones especiales con un estilo original y exclusivo.",
        'fr': "",
        'it': "Abito realizzato in mikado-sable rosa con un design che crea l’effetto visivo di un cappotto-abito, pur essendo un pezzo unico. Lo scollo strutturato con revers e il dettaglio del fiore dipinto a mano in vita donano raffinatezza e un tocco artigianale unico. Un design elegante, perfetto per distinguersi in occasioni speciali con uno stile originale ed esclusivo.", 
        'ca': "Vestit confeccionat en mikado-sable rosa amb un disseny que crea l’efecte visual d’un abric-vestit, tot i ser una única peça. L’escot estructurat amb solapes i el detall d’una flor pintada a mà a la cintura aporten sofisticació i un toc artesanal únic. Un disseny refinat ideal per destacar en ocasions especials amb un estil original i exclusiu.",
    },
    '6186': {
        'en': "Elegant set of a dress and coat crafted in pink crepe, featuring paillette details in white and pink tones that add a subtle touch of sparkle. The coat, with a chimney collar and short sleeves, combines the sophistication of paillettes on the upper part with the lightness of crepe on the lower part, creating movement and fluidity. The strapless dress is adorned with a waistband made of fabric bias strips with a shiny finish, enhancing and defining the silhouette. A perfect combination for memorable occasions.",
        'es': "Elegante conjunto de vestido y abrigo confeccionado en crepé rosa, con detalles de paillettes en tonos blanco y rosa que aportan un sutil toque de brillo. El abrigo, con cuello chimenea y manga corta, combina la sofisticación de las paillettes en la parte superior con la ligereza del crepé en la parte inferior, creando movimiento y fluidez. El vestido, de diseño palabra de honor, está decorado con una franja en la cintura formada por bieses del mismo tejido con acabado brillante, definiendo y estilizando la silueta. Una combinación perfecta para ocasiones memorables.",
        'fr': "",
        'it': "Elegante completo di abito e cappotto realizzato in crêpe rosa, con dettagli in paillettes nei toni del bianco e rosa che aggiungono un tocco di brillantezza sottile. Il cappotto, con colletto a camino e maniche corte, combina la raffinatezza delle paillettes nella parte superiore con la leggerezza del crêpe nella parte inferiore, creando movimento e fluidità. L’abito senza spalline è decorato con una fascia in vita realizzata con strisce di tessuto in sbieco con finitura lucida, valorizzando e definendo la silhouette. Una combinazione perfetta per occasioni memorabili.", 
        'ca': "Conjunt elegant de vestit i abric confeccionat en crep rosa, amb detalls de paillette en tons blanc i rosa que aporten un toc de brillantor subtil. L’abric, amb coll xemeneia i màniga curta, combina la sofisticació del paillette a la part superior amb la lleugeresa del crep a la part inferior, que proporciona moviment i fluïdesa. El vestit, de disseny paraula d’honor, està decorat amb una franja a la cintura formada per biaixos del mateix teixit amb acabat brillant, definint i estilitzant la silueta. Una combinació perfecta per a moments memorables.",
    },
    '6187': {
        'en': "Elegant set of a coat and dress crafted in pink piqué-mikado, embodying the sophistication characteristic of the Teresa Ripoll brand. The coat stands out with its striking pleated collar, a hallmark of the firm, and its front-crossed design with an opening at the lower part. The sleeves feature slits that allow them to be turned, creating a refined decorative cuff. The sleeveless dress showcases a classic design with a subtle decorative piece around the neckline, adding a delicate touch to the ensemble. An ideal choice for special events.",
        'es': "Elegante conjunto de abrigo y vestido confeccionado en piqué-mikado rosa, que refleja la sofisticación característica de la marca Teresa Ripoll. El abrigo destaca por su imponente cuello con pliegues, un sello distintivo de la firma, y su diseño cruzado por delante con una apertura en la parte inferior. Las mangas incluyen cortes que permiten girarlas, creando un refinado puño decorativo. El vestido, sin mangas, presenta un diseño básico con una pieza decorativa alrededor del escote, aportando un toque sutil y delicado al conjunto. Una elección ideal para eventos especiales.",
        'fr': "",
        'it': "Elegante completo di cappotto e abito realizzato in piqué-mikado rosa, che incarna la raffinatezza caratteristica del marchio Teresa Ripoll. Il cappotto si distingue per il suo imponente colletto con pieghe, un segno distintivo della firma, e per il design incrociato sul davanti con apertura nella parte inferiore. Le maniche presentano tagli che permettono di risvoltarle, creando un raffinato polsino decorativo. L’abito senza maniche presenta un design classico con un delicato dettaglio decorativo intorno allo scollo, aggiungendo un tocco sottile all’insieme. Una scelta ideale per eventi speciali.", 
        'ca': "Conjunt elegant d’abric i vestit confeccionat en piqué-mikado rosa, que reflecteix la sofisticació característica de la marca Teresa Ripoll. L’abric destaca pel seu imponent coll amb plecs, un segell distintiu de la firma, i el seu disseny creuat per davant amb obertura a la part inferior. Els talls a les mànigues permeten girar-les, creant un puny decoratiu refinat. El vestit, sense mànigues, presenta un disseny bàsic amb una peça decorativa que envolta l’escot, aportant un toc subtil i delicat al conjunt. Una elecció ideal per a esdeveniments especials.",
    },
    '6188': {
        'en': "A sophisticated set featuring a jumpsuit and coat crafted in pink crepe, perfect for elegant and modern events. The coat combines a structured upper part, with a raised collar and three-quarter sleeves, with a flowing lower part made from a crepe layer, adding lightness and movement to the ensemble. The jumpsuit, with thin straps and a straight neckline, features a waistband detailed with bias elements that enhance the silhouette. A design that blends structure and delicacy with a contemporary touch.",
        'es': "Sofisticado conjunto de mono y abrigo confeccionado en crepé rosa, perfecto para eventos elegantes y modernos. El abrigo combina una parte superior estructurada, con cuello elevado y mangas tres cuartos, con una parte inferior formada por una capa fluida de crepé que aporta ligereza y movimiento al conjunto. El mono, con finos tirantes y escote recto, destaca por una franja en la cintura hecha con bieses que estilizan la silueta. Un diseño que une estructura y delicadeza con un toque contemporáneo.",
        'fr': "",
        'it': "Completo sofisticato composto da tuta e cappotto realizzati in crêpe rosa, perfetto per eventi eleganti e moderni. Il cappotto combina una parte superiore strutturata, con colletto rialzato e maniche a tre quarti, con una parte inferiore realizzata in un fluido strato di crêpe, che dona leggerezza e movimento all’insieme. La tuta, con sottili spalline e scollo dritto, presenta una fascia in vita rifinita con dettagli in sbieco che valorizzano la silhouette. Un design che unisce struttura e delicatezza con un tocco contemporaneo.", 
        'ca': "Conjunt sofisticat de mono i abric confeccionat en crep rosa, ideal per a esdeveniments elegants i moderns. L’abric combina una part superior estructurada, amb coll alçat i mànigues tres quarts, amb una part inferior formada per una capa fluïda de crep, que aporta lleugeresa i moviment al conjunt. El mono, amb tirants fins i escot recte, destaca per una franja a la cintura feta amb biaixos que estilitzen la silueta. Una proposta que uneix estructura i delicadesa amb un toc contemporani.",
    },
}