/**
 * Fitxers implicats: Films.jsx, FilmPlayer.jsx, filmsModel.js
 * Inicialment els vídeos estaven a Vimeo, però han passat a ser arxius mp4 
 * allotjats al propi servidor.
 */
import React from 'react'

export const FilmPlayer = ({ video, onClose }) => {

  const getHeight = () => {
    let height = window.innerHeight - (window.innerHeight * 0.2)
    if (window.innerWidth < window.innerHeight) {
      height = 540 * window.innerWidth / 960;
    }
    return height
  }

  return (
    <div style={styles.container} onClick={onClose}>
      {/*<iframe
        title={`${video}`}
        style={styles.iframe}
        src={`https://player.vimeo.com/video/${video}?autoplay=1&loop=1`}
        height={getHeight()}
        frameBorder={0}
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
      />*/}
      <video
        controls
        autoPlay
        style={styles.iframe}
        height={getHeight()}
        //onClick={(e) => e.stopPropagation()}
      >
        <source src={video} type="video/mp4"/>
      </video>
    </div>
  )
  // playsInline // iOS hevitar que es reprodueixi a pantalla completa
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 30,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,1)',
    zIndex: 1,
  },
  iframe: {
    width: '80%',
  }
}
