import {book_heritage_trans} from '../../i18n/book_heritage.trans'

export const book_heritage = [
    {
        title: '2230',

        light: '/img/2025/light/Fotos/2230-bl-pa-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/2230-bl-pa-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/2230-bl-pa-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/2230-bl-pa-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/2230-bl-pa-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[2230],
    },
    {
        title: '2231',

        light: '/img/2025/light/Fotos/2231-bl-pa-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/2231-bl-pa-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/2231-bl-pa-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/2231-bl-pa-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/2231-bl-pa-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[2231],
    },
    {
        title: '3812',

        light: '/img/2025/light/Fotos/3812-ch-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3812-ch-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3812-ch-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3812-ch-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3812-ch-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3812],
    },
    {
        title: '3813',

        light: '/img/2025/light/Fotos/3813-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3813-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3813-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3813-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3813-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3813],
    },
    {
        title: '3814',

        light: '/img/2025/light/Fotos/3814-ch-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3814-ch-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3814-ch-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3814-ch-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3814-ch-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3814],
    },
    {
        title: '3814',

        light: '/img/2025/light/Fotos/3814-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3814-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3814-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3814-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3814-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3814],
    },
    {
        title: '3815',

        light: '/img/2025/light/Fotos/3815-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3815-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3815-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3815-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3815-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3815],
    },
    {
        title: '3816',

        light: '/img/2025/light/Fotos/3816-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3816-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3816-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3816-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3816-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3816],
    },
    {
        title: '3817',

        light: '/img/2025/light/Fotos/3817-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3817-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3817-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3817-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3817-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3817],
    },
    {
        title: '3818',

        light: '/img/2025/light/Fotos/3818-ch-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3818-ch-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3818-ch-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3818-ch-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3818-ch-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3818],
    },
    {
        title: '3818',

        light: '/img/2025/light/Fotos/3818-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3818-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3818-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3818-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3818-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3818],
    },
    {
        title: '3819',

        light: '/img/2025/light/Fotos/3819-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3819-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3819-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3819-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3819-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3819],
    },
    {
        title: '3820',

        light: '/img/2025/light/Fotos/3820-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3820-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3820-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3820-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3820-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3820],
    },
    {
        title: '3821',

        light: '/img/2025/light/Fotos/3821-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3821-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3821-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3821-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3821-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3821],
    },
    {
        title: '3822',

        light: '/img/2025/light/Fotos/3822-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3822-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3822-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3822-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3822-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3822],
    },
    {
        title: '3823',

        light: '/img/2025/light/Fotos/3823-ch-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3823-ch-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3823-ch-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3823-ch-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3823-ch-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3823],
    },
    {
        title: '3824',

        light: '/img/2025/light/Fotos/3824-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3824-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3824-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3824-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3824-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3824],
    },
    {
        title: '3825',

        light: '/img/2025/light/Fotos/3825-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3825-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3825-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3825-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3825-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3825],
    },
    {
        title: '3826',

        light: '/img/2025/light/Fotos/3826-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3826-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3826-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3826-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3826-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3826],
    },
    {
        title: '3827',

        light: '/img/2025/light/Fotos/3827-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3827-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3827-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3827-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3827-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3827],
    },
    {
        title: '3828',

        light: '/img/2025/light/Fotos/3828-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3828-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3828-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3828-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3828-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3828],
    },
    {
        title: '3829',

        light: '/img/2025/light/Fotos/3829-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3829-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3829-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3829-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3829-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3829],
    },
    {
        title: '3830',

        light: '/img/2025/light/Fotos/3830-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3830-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3830-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3830-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3830-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3830],
    },
    {
        title: '3831',

        light: '/img/2025/light/Fotos/3831-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3831-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3831-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3831-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3831-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3831],
    },
    {
        title: '3832',

        light: '/img/2025/light/Fotos/3832-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3832-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3832-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3832-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3832-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3832],
    },
    {
        title: '3833',

        light: '/img/2025/light/Fotos/3833-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3833-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3833-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3833-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3833-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3833],
    },
    {
        title: '3834',

        light: '/img/2025/light/Fotos/3834-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3834-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3834-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3834-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3834-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3834],
    },
    {
        title: '3835',

        light: '/img/2025/light/Fotos/3835-ch-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3835-ch-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3835-ch-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3835-ch-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3835-ch-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3835],
    },
    {
        title: '3836',

        light: '/img/2025/light/Fotos/3836-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3836-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3836-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3836-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3836-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3836],
    },
    {
        title: '3837',

        light: '/img/2025/light/Fotos/3837-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3837-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3837-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3837-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3837-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3837],
    },
    {
        title: '3838',

        light: '/img/2025/light/Fotos/3838-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3838-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3838-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3838-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3838-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3838],
    },
    {
        title: '3839',

        light: '/img/2025/light/Fotos/3839-bl-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3839-bl-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3839-bl-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3839-bl-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3839-bl-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3839],
    },
    {
        title: '3840',

        light: '/img/2025/light/Fotos/3840-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3840-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3840-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3840-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3840-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3840],
    },
    {
        title: '3841',

        light: '/img/2025/light/Fotos/3841-ch-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3841-ch-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3841-ch-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3841-ch-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3841-ch-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3841],
    },
    {
        title: '3842',

        light: '/img/2025/light/Fotos/3842-bl-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3842-bl-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3842-bl-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3842-bl-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3842-po-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3842],
    },
    {
        title: '3843',

        light: '/img/2025/light/Fotos/3843-ch-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3843-ch-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3843-ch-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3843-ch-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3843-ch-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3843],
    },
    {
        title: '3843',

        light: '/img/2025/light/Fotos/3843-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3843-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3843-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3843-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3843-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3843],
    },
    {
        title: '3844',

        light: '/img/2025/light/Fotos/3844-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3844-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3844-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3844-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3844-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3844],
    },
    {
        title: '3845',

        light: '/img/2025/light/Fotos/3845-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3845-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3845-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3845-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3845-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3845],
    },
    {
        title: '3846',

        light: '/img/2025/light/Fotos/3846-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/3846-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/3846-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/3846-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/3846-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[3846],
    },
    {
        title: '6186',

        light: '/img/2025/light/Fotos/6186-a-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/6186-a-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/6186-a-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/6186-a-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/6186-a-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[6186],
    },
    {
        title: '6186',

        light: '/img/2025/light/Fotos/6186-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/6186-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/6186-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/6186-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/6186-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[6186],
    },
    {
        title: '6187',

        light: '/img/2025/light/Fotos/6187-a-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/6187-a-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/6187-a-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/6187-a-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/6187-a-v-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[6187],
    },
    {
        title: '6188',

        light: '/img/2025/light/Fotos/6188-a-m-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/6188-a-m-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/6188-a-m-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/6188-a-m-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/6188-a-m-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[6188],
    },
    {
        title: '6188',

        light: '/img/2025/light/Fotos/6188-m-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/6188-m-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/6188-m-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/6188-m-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/6188-m-teresaripoll-ss25-clip.mp4',

        //border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        //shadow: 'none',
        trans: book_heritage_trans[6188],
    },
]