import {book_prisma_trans} from '../../i18n/book_prisma.trans'

export const book_prisma = [
    {
        title: '7042',

        light: '/img/2025/light/Fotos/7042-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/7042-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/7042-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/7042-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/7042-v-teresaripoll-ss25-clip.mp4',

        border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        shadow: 'none',
        trans: book_prisma_trans[7042],
    },
    {
        title: '7043',

        light: '/img/2025/light/Fotos/7043-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/7043-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/7043-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/7043-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/7043-v-teresaripoll-ss25-clip.mp4',

        border: '8px solid rgb(231, 224, 206)', // ImageGallery.js
        shadow: 'none',
        trans: book_prisma_trans[7043],
    },
    {
        title: '7044',

        light: '/img/2025/light/Fotos/7044-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/7044-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/7044-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/7044-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/7044-v-teresaripoll-ss25-clip.mp4',

        border: '8px solid rgb(215, 210, 206)', // ImageGallery.js
        shadow: 'none',
        trans: book_prisma_trans[7044],
    },
    {
        title: '7045',

        light: '/img/2025/light/Fotos/7045-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/7045-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/7045-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/7045-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/7045-v-teresaripoll-ss25-clip.mp4',

        border: '8px solid rgb(176, 208, 245)', // ImageGallery.js
        shadow: 'none',
        trans: book_prisma_trans[7045],
    },
    {
        title: '7046',

        light: '/img/2025/light/Fotos/7046-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/7046-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/7046-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/7046-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/7046-v-teresaripoll-ss25-clip.mp4',

        border: '8px solid rgb(235, 206, 164)', // ImageGallery.js
        shadow: 'none',
        trans: book_prisma_trans[7046],
    },
    {
        title: '7047',

        light: '/img/2025/light/Fotos/7047-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/7047-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/7047-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/7047-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/7047-v-teresaripoll-ss25-clip.mp4',

        border: '8px solid rgb(224, 164, 171)', // ImageGallery.js
        shadow: 'none',
        trans: book_prisma_trans[7047],
    },
    {
        title: '7048',

        light: '/img/2025/light/Fotos/7048-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/7048-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/7048-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/7048-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/7048-v-teresaripoll-ss25-clip.mp4',

        border: '8px solid rgb(238, 202, 202)', // ImageGallery.js
        shadow: 'none',
        trans: book_prisma_trans[7048],
    },
    {
        title: '7049',

        light: '/img/2025/light/Fotos/7049-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/7049-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/7049-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/7049-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/7049-v-teresaripoll-ss25-clip.mp4',

        border: '8px solid rgb(171, 182, 237)', // ImageGallery.js
        shadow: 'none',
        trans: book_prisma_trans[7049],
    },
    {
        title: '7050',

        light: '/img/2025/light/Fotos/7050-ch-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/7050-ch-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/7050-ch-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/7050-ch-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/7050-ch-v-teresaripoll-ss25-clip.mp4',

        border: '8px solid rgb(171, 182, 237)', // ImageGallery.js
        shadow: 'none',
        trans: book_prisma_trans[7050],
    },
    {
        title: '7051',

        light: '/img/2025/light/Fotos/7051-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/7051-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/7051-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/7051-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/7051-v-teresaripoll-ss25-clip.mp4',

        border: '8px solid rgb(210, 214, 215)', // ImageGallery.js
        shadow: 'none',
        trans: book_prisma_trans[7051],
    },
    {
        title: '7052',

        light: '/img/2025/light/Fotos/7052-bl-pa-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/7052-bl-pa-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/7052-bl-pa-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/7052-bl-pa-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/7052-v-teresaripoll-ss25-clip.mp4',

        border: '8px solid rgb(250, 182, 182)', // ImageGallery.js
        shadow: 'none',
        trans: book_prisma_trans[7052],
    },
    {
        title: '7053',

        light: '/img/2025/light/Fotos/7053-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/7053-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/7053-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/7053-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/7053-v-teresaripoll-ss25-clip.mp4',

        border: '8px solid rgb(154, 178, 224)', // ImageGallery.js
        shadow: 'none',
        trans: book_prisma_trans[7053],
    },
    {
        title: '7054',

        light: '/img/2025/light/Fotos/7054-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/7054-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/7054-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/7054-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/7054-v-teresaripoll-ss25-clip.mp4',

        border: '8px solid rgb(170, 228, 188)', // ImageGallery.js
        shadow: 'none',
        trans: book_prisma_trans[7054],
    },
    {
        title: '7055',

        light: '/img/2025/light/Fotos/7055-v-teresaripoll-ss25.jpg',
        back_light: '',
        front_light: '',
        detail_light: '/img/2025/light/Detalls/7055-v-teresaripoll-ss25.jpg',

        hight: '/img/2025/hight/Fotos/7055-v-teresaripoll-ss25.jpg',
        back_hight: '',
        front_hight: '',
        detail_hight: '/img/2025/hight/Detalls/7055-v-teresaripoll-ss25.jpg',

        video: '/img/2025/movies/7055-v-teresaripoll-ss25-clip.mp4',

        border: '8px solid rgb(231, 224, 206)', // ImageGallery.js
        shadow: 'none',
        trans: book_prisma_trans[7055],
    },
]