import { book_50_years } from "./books/book_50_years"
import { book_party_one } from "./books/book_party_one"
import { book_party_two } from "./books/book_party_two"
import { book_paris } from './books/book_paris'
import { book_rainbow } from "./books/book_rainbow"
import { book_city } from "./books/book_city"
import { book_charming } from "./books/book_charming"
import { bookn_roaring20s } from "./books/bookn_roaring20s"
import { bookn_sweet } from "./books/bookn_sweet"
import { bookn_elegance } from "./books/bookn_elegance"
import { bookn_dreams } from "./books/bookn_dreams"
import { book_kaleidoscope } from "./books/book_kaleidoscope"
import { book_danube } from "./books/book_danube"
import { book_chroma } from "./books/book_chroma"
import { book_timeless } from "./books/book_timeless"
import { book_prisma } from "./books/book_prisma"
import { book_heritage } from "./books/book_heritage"

// TODO: 7000 (Chroma Collection) - Timeless Collection
export const BOOK_HERITAGE=0
export const BOOK_PRISMA=1
export const BOOK_TIMELESS=2
export const BOOK_CHROMA=3
export const BOOK_DANUBE=4
export const BOOK_KALEIDOSCOPE=5
export const BOOK_PARIS=6
export const BOOK_RAINBOW=7
export const BOOK_50_YEARS=8
export const BOOK_PARTY_ONE=9
export const BOOK_PARTY_TWO=10
export const BOOK_CITY=11
export const BOOK_CHARMING=12
export const BOOKN_ROARING20S=13
export const BOOKN_SWEET=14
export const BOOKN_ELEGANCE=15
export const BOOKN_DREAMS=16


export const galleries = [
    {
        title: 'Book Heritage',
        imgDesktop: '/img/carousel/Banner-SS25-Heritage-Ord-1.jpg',
        imgMobile: '/img/carousel/Banner-SS25-Heritage-Mov-1.jpg',
        book: book_heritage,
    },
    {
        title: 'Book Prisma',
        imgDesktop: '/img/carousel/Banner-SS25-Prisma-Ord-1.jpg',
        imgMobile: '/img/carousel/Banner-SS25-Prisma-Mov-1.jpg',
        book: book_prisma,
    },
    {
        title: 'Book Timeless',
        imgDesktop: '/img/carousel/timeless-banner-ss24-ord-1.jpg',
        imgMobile: '/img/carousel/timeless-banner-ss24-mob-1.jpg',
        book: book_timeless,
    },
    {
        title: 'Book Chroma',
        imgDesktop: '/img/carousel/chroma-banner-ss24-ord-1.jpg',
        imgMobile: '/img/carousel/chroma-banner-ss24-mob-1.jpg',
        book: book_chroma,
    },
    {
        title: 'Book Danube',
        imgDesktop: '/img/2023/danube/banners/danube.jpg',
        imgMobile: '/img/2023/danube/banners/danube-mbl.jpg',
        book: book_danube,
    },
    {
        title: 'Book Kaleidoscope',
        imgDesktop: '/img/2023/kaleidoscope/banners/kaleidoscope.jpg',
        imgMobile: '/img/2023/kaleidoscope/banners/kaleidoscope-mbl.jpg',
        book: book_kaleidoscope,
    },
    {
        title: 'Book Paris',
        imgDesktop: '/img/carousel/2-SS22-bugam-portada-escriptori-v1.jpg',
        imgMobile: '/img/carousel/2-SS22-bugam-portada-mobil-v1.jpg',
        book: book_paris,
    },
    {
        title: 'Book Rainbow Origin',
        imgDesktop: '/img/carousel/rainbow1.jpg',
        imgMobile: '/img/carousel/rainbow1_mobil.jpg',
        book: book_rainbow,
    },
    {
        title: 'Book 50 Years',
        imgDesktop: '/img/headers/50years-desktop.jpg',
        imgMobile: '/img/headers/50years-mobile.jpg',
        book: book_50_years,
    },
    {
        title: 'Book Party One',
        imgDesktop: '/img/headers/party-one-desktop.jpg',
        imgMobile: '/img/headers/party-one-mobile.jpg',
        book: book_party_one,
    },
    {
        title: 'Book Party Two',
        imgDesktop: '/img/headers/party-two-desktop.jpg',
        imgMobile: '/img/headers/party-two-mobile.jpg',
        book: book_party_two,
    },
    {
        title: 'Book City',
        imgDesktop: '/img/headers/city-desktop.jpg',
        imgMobile: '/img/headers/city-mobile.jpg',
        book: book_city,
    },
    {
        title: 'Book Charming',
        imgDesktop: '/img/headers/charming-desktop.jpg',
        imgMobile: '/img/headers/charming-mobile.jpg',
        book: book_charming,
    },
    {
        title: 'Book Roaring 20 S',
        imgDesktop: '/img/headers/roaring20s-desktop.jpg',
        imgMobile: '/img/headers/roaring20s-mobile.jpg',
        book: bookn_roaring20s,
    },
    {
        title: 'Book Sweet',
        imgDesktop: '/img/headers/sweet-desktop.jpg',
        imgMobile: '/img/headers/sweet-mobile.jpg',
        book: bookn_sweet,
    },
    {
        title: 'Book Elegance',
        imgDesktop: '/img/headers/elegance-desktop.jpg',
        imgMobile: '/img/headers/charmig-mobile.jpg',
        book: bookn_elegance,
    },
    {
        title: 'Book Dreams',
        imgDesktop: '/img/headers/dreams-desktop.jpg',
        imgMobile: '/img/headers/charmig-mobile.jpg',
        book: bookn_dreams,
    },
]
